<template>
  <!-- dropdown -->
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="rounded block" type="button">
        <!-- <img src="@/assets/user.svg" /> -->
        <div class="flex">
          <UserIcon />
        </div>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden pt-2"
      >
        <MenuItem v-slot="{ active }">
          <router-link class="px-4 py-4 flex" to="/user-center" :class="[active ? 'bg-gray-darkest' : '']">
            <img :src="user.info.avatar_url" class="rounded-full w-10 h-10 flex-shrink-0 mr-7" />
            <div>
              <!-- <p class="text-sm">
                <EmailPhone :text="user.info.name" />
              </p> -->
              <Balance />
            </div>
          </router-link>
        </MenuItem>

        <MenuItem v-for="link in links" :key="link.title" v-slot="{ active }">
          <div
            v-if="link.click"
            :class="['cursor-pointer', active ? 'bg-gray-darkest' : '', 'flex items-center px-[30px] py-2.5 text-sm']"
            @click="link.click"
          >
            <img class="mr-8 h-5 w-5 text-black-70" :src="link.icon" />
            <span>{{ link.title }}</span>
          </div>
          <router-link
            v-else
            :to="link.to"
            :class="['relative', active ? 'bg-gray-darkest' : '', 'flex items-center px-[30px] py-2.5 text-sm']"
          >
            <img class="mr-8 h-5 w-5 text-black-70" :src="link.icon" />
            <span>{{ link.title }}</span>
            <div
              v-if="link.to === '/notifications' && unreadNotification"
              class="absolute flex justify-center right-10 top-1/2 -translate-y-1/2 rounded-full bg-red-200 text-red-700 w-6 h-6 text-xs leading-[26px] font-bold"
            >
              {{ unreadNotification }}
            </div>
          </router-link>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>

  <Balance class="hidden sm:flex ml-6" v-if="$route.path !== '/'">
    <button class="text-yellow" @click="store.commit('SET_MODAL', 'topup')">
      <!-- <PlusCircleIcon class="w-5 ml-2.5" /> -->
      <TopupIcon class="ml-2.5" />
    </button>
  </Balance>
</template>

<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { destroyToken, getToken } from '@/services/jwt'
import Balance from './Balance'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
// import { PlusCircleIcon } from '@heroicons/vue/solid'
import { useI18n } from 'vue-i18n'
import TopupSVG from '@/assets/my-info/topup.svg'
import BadgeSVG from '@/assets/my-info/badge.svg'
import InfoSVG from '@/assets/my-info/info.svg'
import InvenSVG from '@/assets/my-info/inven.svg'
import DollarSVG from '@/assets/my-info/dollar.svg'
import BellSVG from '@/assets/my-info/bell.svg'
import LogoutSVG from '@/assets/my-info/logout.svg'
import UserSVG from '@/assets/my-info/user.svg'
// import EmailPhone from '@/components/_basic/EmailPhone'
import UserIcon from '@/components/svgs/UserIcon'
import TopupIcon from '@/components/svgs/TopupIcon'

const { t } = useI18n()
const store = useStore()

const polling = ref(null)
const user = computed(() => store.state.user)

const unreadNotification = computed(() => store.state.notifications.unread)

function exit() {
  destroyToken()
  store.commit('SET_NOT_LOGIN')
}

function pollData() {
  polling.value = setInterval(() => user.value.isLogin && store.dispatch('LOAD_USER'), 10000)
}

onMounted(() => {
  if (user.value.isLogin || getToken()) {
    store.dispatch('LOAD_USER')
    store.dispatch('notifications/getNotificationList')
  }
  pollData()
})

onUnmounted(() => {
  clearInterval(polling.value)
})

const links = [
  {
    click: () => store.commit('SET_MODAL', 'topup'),
    to: '#',
    title: t('myinfo.topup'),
    icon: TopupSVG,
  },
  {
    to: '/user-center/account',
    title: t('myinfo.myinf'),
    icon: UserSVG,
  },
  {
    to: '/user-center/inventory',
    title: t('myinfo.inven'),
    icon: InvenSVG,
  },
  {
    to: '/my-refer',
    title: t('myinfo.promo'),
    icon: BadgeSVG,
  },
  {
    to: '/user-center/trade-history',
    title: t('myinfo.topuphist'),
    icon: DollarSVG,
  },
  {
    to: '/real-name',
    title: t('myinfo.realname'),
    icon: InfoSVG,
  },
  {
    to: '/notifications',
    title: t('myinfo.notif'),
    icon: BellSVG,
  },
  {
    click: exit,
    to: '#',
    title: t('myinfo.exit'),
    icon: LogoutSVG,
  },
]
</script>
