import { createApp } from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ApiService from './services/api'
import i18n from './i18n'
import '@/styles/index.css'
import 'element-plus/lib/theme-chalk/base.css'
import Pagination from '@/components/_basic/Pagination/index'
import ScrollDown from '@/components/_basic/ScrollDown'
import Skeleton from '@/components/_basic/Skeleton'
import Button from '@/components/_basic/Button'
import ButtonLink from '@/components/_basic/ButtonLink'
import TabMobile from '@/components/_basic/TabMobile'
import Avatar from '@/components/_basic/Avatar'
import Modal from '@/components/Modal'
import Input from '@/components/_basic/Input'
import Spinner from '@/components/_basic/Spinner'
import InputFeedback from '@/components/_basic/InputFeedback'
import Switch from '@/components/_basic/Switch'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App)

app.use(i18n)
app.use(store)
app.use(router)

ApiService.init()

app.component('Pagination', Pagination)
app.component('ScrollDown', ScrollDown)
app.component('Skeleton', Skeleton)
app.component('Button', Button)
app.component('Spinner', Spinner)
app.component('ButtonLink', ButtonLink)
app.component('tab-mobile', TabMobile)
app.component('avatar', Avatar)
app.component('Modal', Modal)
app.component('Switch', Switch)
app.component('i-input', Input)
app.component('i-input-feedback', InputFeedback)
app.component('date-picker', VueDatePicker)

app.mount('#app')
