<template>
  <div class="balance flex items-center text-black-70 font-semibold relative select-none">
    <div class="flex flex-col">
      <span class="text-sm">
        <EmailPhone :text="user.info.name" />
      </span>
      <span class="text-variants-primary">
        {{ symbol }}
        <span>{{ displayBalance[0] }}</span
        >.<span class="text-xs">{{ displayBalance[1] }}</span>
      </span>
    </div>

    <div class="absolute right-1 text-sm delta" v-if="delta !== 0 && !isNaN(delta)">
      <span
        :class="{
          'text-variants-success': delta > 0,
          'text-variants-warning': delta < 0,
        }"
      >
        {{ delta > 0 ? '+' : '' }}{{ symbol }}{{ delta }}
      </span>
    </div>

    <slot></slot>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import EmailPhone from '@/components/_basic/EmailPhone'

const store = useStore()
const symbol = computed(() => store.state.app.config.currency_symbol)
const delta = ref(0)
const user = computed(() => store.state.user)

const balance = computed(() => {
  if (!user.value || !user.value.info || !user.value.info.balances) return ''
  return user.value.info.balances.find(i => i.currency_type === 0).balance
})

const displayBalance = computed(() => {
  if (!balance.value) return ['0', '00']
  let rv = balance.value
  if (rv === 0) {
    rv = '0.00'
  }
  return rv.split('.')
})

watch(
  () => balance.value,
  (newCount, oldCount) => {
    if (oldCount === undefined || newCount === undefined) return
    const newAmount = `${newCount}`.replace(/,/g, '')
    const oldAmount = `${oldCount}` !== undefined && `${oldCount}`.replace(/,/g, '')
    delta.value = Math.floor(100 * (parseFloat(newAmount) - parseFloat(oldAmount))) / 100
    setTimeout(() => {
      delta.value = 0
    }, 5000)
  }
)
</script>

<style scoped>
.delta {
  animation: showup 4s normal forwards ease;
}

@keyframes showup {
  0% {
    opacity: 0;
    top: -10em;
  }
  20% {
    opacity: 1;
    top: 0;
  }
  70% {
    opacity: 1;
    top: 0.5em;
  }
  100% {
    opacity: 0;
    top: -3em;
  }
}
</style>
