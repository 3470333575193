const isCN = document.location.hostname.includes('lucksec.cn');
const isProd = process.env.NODE_ENV === 'production';
const PROD_ASSET_URL = isCN ?
  'https://asset.lucksec.cn/' :
  'https://asset.lucksec.com/';
const PROD_BASE_URL = isCN ?
  'https://api.lucksec.cn' :
  'https://api.lucksec.com';
export const ASSET_URL = isProd ?
  PROD_ASSET_URL :
  (process.env.VUE_APP_ASSET_ENDPOINT || PROD_ASSET_URL);
export const BASE_URL = isProd ? PROD_BASE_URL : (process.env.VUE_APP_ENDPOINT || PROD_BASE_URL);
export const API_URL = `${BASE_URL}/api`;
export default API_URL;
