<template>
  <label class="relative cursor-pointer inline-block h-6 pl-12 text-sm leading-6 select-none whitespace-nowrap">
    <slot />
    <input
      type="checkbox"
      v-bind="$attrs"
      class="hidden w-0 h-0"
      :checked="state"
      :value="state"
      @change="handleChange"
    />
    <span
      class="absolute cursor-pointer w-10 h-6 rounded-full top-0 left-0 bg-gray-darkest transition-all before:contents before:absolute before:w-6 before:h-6 before:left-1 before:bottom-1"
      style="box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.08)"
    >
      <span
        class="absolute w-4 h-4 rounded-full left-1 top-1 transition-all"
        :class="{
          'bg-yellow translate-x-[15px]': state,
          'bg-gray': !state,
        }"
      ></span>
    </span>
  </label>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  modelValue: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue'])
const state = ref(props.modelValue)

watch(
  () => state.value,
  () => {
    emit('update:modelValue', state.value)
  }
)

watch(
  () => props.modelValue,
  val => {
    state.value = val
  }
)

const handleChange = e => {
  state.value = e.target.checked
  emit('update:modelValue', e.target.checked)
}
</script>
