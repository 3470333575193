<template>
  <Modal :visible="showModal" title="LUCKSEC盲盒规则说明" @close="onClose">
    <div class="text-left space-y-4">
      <section class="overflow-auto max-h-[400px]">
        <p class="text-secondary" v-html="content"></p>
      </section>
    </div>
    <a target="_blank" class="text-yellow flex items-center justify-center mt-10" href="/page/serve-rule">
      在新页面查看
      <ArrowSmRightIcon class="w-5" />
    </a>
  </Modal>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { ArrowSmRightIcon } from '@heroicons/vue/outline'
import Modal from '@/components/Modal'
import { AppService } from '@/services/api'

const store = useStore()
const title = ref(null)
const content = ref(null)
const loaded = ref(false)

const showModal = computed(() => store.state.modal === 'rule-modal')

const loadPage = async () => {
  try {
    const { data } = await AppService.getPage('serve-rule')
    title.value = data.title
    content.value = data.content
  } catch {
    //
  } finally {
    loaded.value = true
  }
}

function onClose() {
  store.commit('SET_MODAL', '')
}

loadPage()
</script>
