<template>
  <Modal title="充值" @close="onHide" @show="onShow" :visible="openTopup">
    <template #title>
      <button
        v-if="type"
        type="button"
        class="absolute left-4 top-6 bg-white rounded-md text-black-70 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow"
        @click="onShow"
      >
        <ChevronLeftIcon class="w-6 h-6" aria-hidden="true" />
        <span class="sr-only">Back</span>
      </button>
      <h5>{{ modalTitle }}</h5>
    </template>

    <div class="text-left" v-if="!qrcodeUrl">
      <!-- cdk -->
      <template v-if="type === 'cdk'">
        <div class="font-semibold mb-5">请输入充值码</div>
        <div class="flex items-center space-x-2.5 mb-2.5">
          <label class="flex-shrink-0" form="cdk">充值码:</label>
          <div class="flex-1 space-y-2">
            <Input :disabled="generating" type="text" placeholder="输入16位CDK" id="cdk" maxlength="16" v-model="cdk" />
            <InputFeedback :value="cdk" :state="cdk && cdk.length === 16">CDK 格式不合法</InputFeedback>
          </div>
          <Button @onclick="topupCdk" class="flex-shrink-0 w-[100px]" :disabled="generating">兑换 </Button>
        </div>

        <div class="my-7 text-sm">
          以您需要的方式购买
          <span class="font-semiboid">LuckSec</span>钱包卡（CDKey）。 通过微信支付，支付宝，银联，QQ钱包等方式。
        </div>

        <TabSelect :tabs="fastCdkLink" :gap="4">
          <template v-slot="{ item }">
            <a target="_blank" class="block px-3" :href="item.link">{{ symbol }}{{ item.amount }}</a>
          </template>
        </TabSelect>

        <div class="text-red-500" v-if="cdkAmount > 0">成功充值 {{ symbol }}{{ cdkAmount }}</div>
      </template>

      <!-- alipay -->
      <template v-else-if="type === 'alipay'">
        <div class="font-semibold mb-5">选择充值金额</div>
        <div class="mb-5 text-sm">货币换算：Ł 1 = ¥ 1</div>
        <div v-if="cxka" class="mb-7">
          <TabSelect :tabs="fast" :gap="4" :formatText="(i) => `${symbol}${i}`" @onSelect="amount = fast[$event]" />
        </div>
        <div class="flex items-center space-x-2.5 mb-2.5">
          <label class="flex-shrink-0 text-sm" form="otp">充值额度: {{ symbol }}</label>
          <div class="flex-1">
            <Input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <InputFeedback :value="amount" :state="amount >= 10 && amount <= 1500"
              >充值金额必须在 {{ symbol }}10 - {{ symbol }}1500之间
            </InputFeedback>
          </div>
          <Button @onclick="topup" class="flex-shrink-0 w-[100px] py-3" :disabled="generating || amount < 10"
            >充值
          </Button>
        </div>

        <span class="text-sm" v-if="generating">正在生成二维码，请稍后...</span>
      </template>

      <!-- alipay-ext -->
      <template v-else-if="type === 'alipay-ext'">
        <div class="font-semibold mb-5">选择充值金额</div>
        <div class="mb-5 text-sm">货币换算：Ł 1 = ¥ 1</div>
        <div class="flex items-center space-x-2.5 mb-2.5">
          <label class="flex-shrink-0 text-sm" form="otp">充值额度: {{ symbol }}</label>
          <div class="flex-1 space-y-2">
            <Input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <InputFeedback :value="amount" :state="amount >= 10 && amount <= 1500"
              >充值金额必须在 {{ symbol }}10 - {{ symbol }}1500之间
            </InputFeedback>
          </div>
          <Button @onclick="topup" class="flex-shrink-0 w-[100px] py-3" :disabled="generating || amount < 10"
            >充值
          </Button>
        </div>

        <span class="text-sm" v-if="generating">正在生成二维码，请稍后...</span>
      </template>

      <!-- alipay-face -->
      <template v-else-if="type === 'alipay-face'">
        <div class="flex items-center space-x-2.5 mb-2.5">
          <label class="flex-shrink-0 text-sm" form="otp">充值额度: {{ symbol }}</label>
          <div class="flex-1 space-y-2">
            <Input
              :disabled="generating"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <InputFeedback :value="amount" :state="amount >= 30 && amount <= 1500"
              >充值金额必须在 {{ symbol }}30 - {{ symbol }}1500之间
            </InputFeedback>
          </div>
          <Button @onclick="topup" class="flex-shrink-0 w-[100px] py-3" :disabled="generating">充值 </Button>
        </div>

        <div class="flex items-center space-x-2.5 my-2.5">
          <label class="flex-shrink-0" form="mobile">手机号</label>
          <div class="flex-1 space-y-2">
            <Input
              :disabled="generating"
              type="number"
              placeholder="手机号"
              id="mobile"
              maxlength="11"
              v-model="mobile"
            />
            <InputFeedback :value="mobile" :state="checkMobile(mobile)"> 手机号不合法 </InputFeedback>
          </div>
        </div>

        <div class="flex items-center space-x-2.5 my-2.5">
          <label class="flex-shrink-0" form="alipayAccount">支付宝账号</label>
          <div class="flex-1 space-y-2">
            <Input :disabled="generating" placeholder="请填写支付宝账号" id="alipayAccount" v-model="alipayAccount" />
            <InputFeedback :value="alipayAccount" :state="alipayAccount.length > 5">支付宝账号不合法 </InputFeedback>
          </div>
        </div>

        <span class="text-sm" v-if="generating">正在生成二维码，请稍后...</span>
      </template>

      <!-- wechat -->
      <!-- <template v-else-if="type === 'wechat'">
        <div class="font-semibold mb-5">选择充值金额</div>
        <div class="mb-5 text-sm">货币换算：Ł 1 = ¥ 1</div>
        <div v-if="cxka" class="mb-7">
          <TabSelect :tabs="wechatFast" :gap="4" :formatText="i => `{{symbol}}${i}`" @onSelect="amount = wechatFast[$event]" />
        </div>
        <div class="flex items-center space-x-2.5 mb-2.5">
          <label class="flex-shrink-0" form="otp">充值额度: {{symbol}}</label>
          <div class="flex-1 space-y-2">
            <Input
              :disabled="generating || cxka"
              type="number"
              placeholder="充值额度"
              id="otp"
              maxlength="6"
              :min="3"
              :max="200"
              v-model="amount"
            />
            <InputFeedback :value="amount" :state="amount >= 30 && amount <= 1500"
              >充值金额必须在 {{symbol}}30 - {{symbol}}1500之间
            </InputFeedback>
          </div>
          <Button @onclick="topup" class="flex-shrink-0 w-[100px]" :disabled="generating">充值 </Button>
        </div>

        <span class="text-sm" v-if="generating">正在生成二维码，请稍后...</span>
      </template> -->

      <!-- ref -->
      <template v-else-if="type === 'ref-setting'">
        <div class="font-semibold mb-5">请输入促销码</div>
        <RefSetting />
      </template>

      <!-- home -->
      <form v-else @submit.prevent>
        <div class="font-semibold mb-5">请选择充值方式</div>
        <div>
          <div class="text-sm">
            <button
              name="alipay"
              @click="onSubmit('alipay')"
              v-if="supported && supported.includes('alipay')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'alipay' }"
            >
              <img src="@/assets/topup/alipay.svg" alt />
              <h5>扫码支付</h5>
            </button>

            <button
              @click="onSubmit('alipay-face')"
              name="alipay-face"
              v-if="supported && supported.includes('alipay-face')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'alipay-face' }"
            >
              <img src="@/assets/topup/alipay.svg" alt />
              <h5>转账支付</h5>
            </button>

            <button
              @click="onSubmit('alipay-ext')"
              name="alipay-ext"
              v-if="supported && supported.includes('alipay-ext')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'alipay-ext' }"
            >
              <img src="@/assets/topup/alipay.svg" alt />
              <h5>转账支付2</h5>
            </button>

            <button
              @click="onSubmit('alipay-cxka')"
              name="alipay-cxka"
              v-if="supported && supported.includes('alipay-cxka')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'alipay-ext' }"
            >
              <img src="@/assets/topup/alipay-logo.svg" alt />
              <h5>支付宝扫码支付2</h5>
            </button>

            <!-- <button
              @click="onSubmit('wechat')"
              name="wechat"
              v-if="supported && supported.includes('wechat')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'wechat' }"
            >
              <img src="@/assets/topup/wechat.svg" alt />

              <h5>微信扫码支付</h5>
            </button> -->

            <!-- <button
              @click="onSubmit('wechat-cxka')"
              name="wechat-cxka"
              v-if="supported && supported.includes('wechat-cxka')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
            >
              <img src="@/assets/topup/wechat.svg" alt />

              <h5>微信扫码支付2</h5>
            </button> -->

            <button
              @click="onSubmit('cdk')"
              name="cdk"
              v-if="supported && supported.includes('cdk')"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'cdk' }"
            >
              <img src="@/assets/topup/cdk.svg" alt />
              <h5>充值码</h5>
              <!-- <span class="bg-yellow px-2 rounded-sm text-xs block">限时奖励: 8%</span> -->
            </button>

            <button
              type="submit"
              @click="onSubmit('ref-setting')"
              name="ref-setting"
              class="w-full rounded p-5 hover:bg-gray-darkest flex space-x-2.5 cursor-pointer items-center"
              :class="{ 'bg-gray-darkest': type === 'ref-setting' }"
            >
              <img src="@/assets/topup/refer-code.svg" alt />
              <h5>促销码</h5>
            </button>
          </div>

          <div class="text-black-70 text-sm space-x-2.5 mt-5">
            <input id="agreement" required checked name="agreement" type="checkbox" class="rounded-sm" v-model="ack" />
            <label class="agreement-label" for="agreement">
              我已阅读并同意
              <a class="text-[#2563eb]" target="_blank" href="/page/serve-rule">
                {{ '《商城盲盒&服务协议》' }}
              </a>
            </label>
            <div class="text-red" v-if="!ack">
              <div class="absolute left-16 text-red-500">请阅读并同意用户协议</div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- qr code -->
    <div class="text-left" v-if="qrcodeUrl">
      <div class="font-semibold mb-5">请用{{ type === 'wechat' ? '微信' : '支付宝' }}扫码以下二维码</div>
      <div class="mb-2.5" v-if="cnyAmount">
        充值金额为：
        <span class="text-[#ebc13d]">{{ symbol }}{{ cnyAmount }}</span>
      </div>
      <router-link class="mb-2.5" v-if="type === 'alipay-ext'" target="_blank" :to="qrcodeUrl">
        <Button>点击此处完成支付</Button>
      </router-link>
      <div class="md:flex md:space-x-5" v-else>
        <div class="flex-1 text-center">
          <div class="m-auto md:m-0 inline-block">
            <img
              height="100%"
              width="194"
              :src="qrcodeUrl"
              v-if="!!qrcodeUrl"
              alt="qrcode"
              @load="qrcodeSkeleton = false"
            />
            <Skeleton v-if="qrcodeSkeleton" height="194px" width="194px" />
          </div>

          <p class="text-red-500" v-if="isSuccess">支付成功</p>
        </div>
        <div class="flex-1 flex text-sm">
          <ol class="list-decimal m-auto" v-if="type == 'wechat' || type == 'alipay'">
            <li>使用{{ type === 'wechat' ? '微信' : '支付宝' }}扫一扫支付</li>
            <li>如遇商家限制可降低金额再次尝试</li>
          </ol>

          <div class="m-auto" v-if="type == 'alipay-face'">
            <p>请扫码前请详读注意事项，否则造成无法到账或其他经济损失由您个人承担。注意事项如下：</p>
            <ol class="list-decimal">
              <li>扫码后按提示输入相应的金额，否则无法到账。</li>
              <li>单次充值二维码仅可付款一次，多付或重复付款，网站概不负责。</li>
              <li>付款到账可能需要5-10分钟，如果未到账请查阅帮助。</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="h-0 w-full border-t border-[#00000022] mb-5 mt-9" />

    <!-- footer -->
    <div class="p-2 rounded-sm">
      <ul class="text-xs text-black-50 list-disc leading-[18px] text-left pl-4">
        <li class="mb-1">货币换算：Ł 1 = ¥ 1</li>
        <li class="mb-1">
          <span class="text-variants-warning"
            >盲盒游戏中不同物品设有不同获得概率，并不是百分之百掉落某一物品，请理性消费，切勿上头！</span
          >
        </li>
        <li class="mb-1">至少需要充值35元才能配送</li>
        <li class="mb-1">
          使用微信/支付宝支付时，有可能由于库存不足等原因造成购买失败，如您主动将商品退回系统（放弃提货），获得的金额将会进入LuckSec余额中，不会退回原支付账户，请谨慎选择！
        </li>
        <li class="mb-1">
          付款可能需要5-10分钟才能到达账户，<span class="text-variants-warning">18岁以下的个人不得使用这项服务。</span>
        </li>
        <li class="mb-1">
          受疫情影响，部分地区因疫情防控政策影响，商品到货可能会有延期，特殊时间给您带来的不变，敬请谅解！具体详情可咨询在线客服~
        </li>
        <li class="mb-1">
          Lucksec账户余额不可提现，不可转赠他人。由于相关法律法规的限制，我们不允许退款已经用于开箱或通过商品转卖收集的余额。只要没有用于任何用途，您可以随时要求退款。为避免费用或问题，请联系我们退款。同时，请务必谨慎选择，并确认您的消费需求后再进行充值。
        </li>
        <li>{{ contact }}</li>
      </ul>
    </div>
  </Modal>

  <Modal :visible="openTopupResult" @close="onCloseResult">
    <template #title>充值完成</template>
    <div class="mb-7 space-y-7">
      <p>您通过支付宝扫码支付，成功充值{{ symbol }}{{ amount }}。</p>
      <p>您的当前余额为：{{ symbol }}{{ user.info.balances.find((i) => i.currency_type === 0).balance }}</p>
    </div>
    <Button class="w-full mb-5" @click="onCloseResult">完成</Button>
    <Button class="w-full" outline @click="continueTopup">继续充值</Button>
  </Modal>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import nprogress from 'nprogress/nprogress'
import { useStore } from 'vuex'
import { UserService } from '@/services/api'

import Input from '@/components/_basic/Input'
import InputFeedback from '@/components/_basic/InputFeedback'
import TabSelect from '@/components/TabSelect'
import { API_URL } from '@/config'
import RefSetting from './RefSetting'
import Modal from '@/components/Modal'
import { ChevronLeftIcon } from '@heroicons/vue/outline'
import { contacts } from '@/shared'

const contact = ref(contacts)
const ack = ref(false)
const store = useStore()
const isSuccess = ref(false)
const qrcodeUrl = ref(null)
const cdkAmount = ref(0)
const generating = ref(false)
const amount = ref(0)
const orderNo = ref(null)
const cdk = ref(null)
const mobile = ref(localStorage.getItem('mobile') || '')
const alipayAccount = ref(localStorage.getItem('alipayAccount') || '')
const fast = ref([35, 60, 100, 300, 500, 800, 1000, 1500])
// const wechatFast = ref([500, 800, 1000, 1500]);
const fastCdkLink = ref([])
const cnyAmount = ref(null)
const polling = ref(null)
const referString = ref(null)
const type = ref(null)
const cxka = ref(false)
// const option = ref(['alipay', 'wechat']);
const qrcodeSkeleton = ref(true)
const result = ref(null)
const user = computed(() => store.state.user)
const openTopup = computed(() => store.state.modal === 'topup')
const openTopupResult = computed(() => store.state.modal === 'topup-result')
const supported = computed(() => user.value.info.supported_payments)
const symbol = computed(() => store.state.app.config.currency_symbol)
const modalTitle = computed(() => {
  switch (type.value) {
    case 'cdk':
      return '充值码'
    case 'ref-setting':
      return '促销码'
    case 'wechat':
      return '微信 扫码支付'
    case 'wechat-cxka':
      return '微信 扫码支付'
    case 'alipay':
      return '支付宝 扫码支付'
    case 'alipay-face':
      return '支付宝 扫码支付'
    case 'alipay-ext':
      return '支付宝 扫码支付'
    case 'alipay-cxka':
      return '支付宝 扫码支付'
    default:
      return '充值'
  }
})

const onSubmit = (v) => {
  if (!ack.value) {
    return false
  }
  if (v) {
    if (v === 'wechat-cxka') {
      type.value = 'wechat'
      cxka.value = true
      return true
    }
    if (v === 'alipay-cxka') {
      type.value = 'alipay'
      cxka.value = true
      return true
    }
    type.value = v
  }
  return true
}

const checkMobile = (val) => parseInt(val).toString().length === 11

// function cdkOnClick() {
//   type.value = 'cdk';
// }
function onHide() {
  nprogress.done()
  store.commit('SET_MODAL', null)
  if (polling.value !== null) {
    clearInterval(polling.value)
  }
  cdk.value = null
}

function topupCdk() {
  if (!cdk.value || cdk.value.length < 16) return
  generating.value = true
  cdkAmount.value = 0
  nprogress.start()
  UserService.topupCdk({
    cdk: cdk.value,
    referString: referString.value,
  })
    .then((res) => {
      nprogress.done()
      generating.value = false
      cdkAmount.value = res.data.amount
      result.value = true
    })
    .catch((e) => {
      generating.value = false
      store.commit('SET_TOAST', {
        data: '对不起，CDK无效',
        type: 'error',
      })
      nprogress.done()
      console.log(e)
    })
}

function onShow() {
  cxka.value = false
  type.value = null
  cdk.value = null
  amount.value = 0
  cdkAmount.value = 0
  qrcodeUrl.value = null
  qrcodeSkeleton.value = true
  cnyAmount.value = null
  isSuccess.value = false
  if (polling.value !== null) {
    clearInterval(polling.value)
  }
}

function onCloseResult() {
  result.value = false
  store.commit('SET_MODAL', null)
  qrcodeUrl.value = null
}

function continueTopup() {
  store.commit('SET_MODAL', 'topup')
}

function topupSuccessful() {
  result.value = true
  onHide()
  store.commit('SET_MODAL', 'topup-result')
}

// function topupFailed() {
//   result.value = false;
//   store.commit('SET_MODAL', 'topup-result');
// }

function topup() {
  amount.value = parseFloat(amount.value)
  if (!type.value) {
    type.value = localStorage.getItem('payment-type') || 'wechat'
  }
  localStorage.setItem('payment-type', type.value)
  localStorage.setItem('alipayAccount', alipayAccount.value)
  localStorage.setItem('mobile', mobile.value)
  generating.value = true
  qrcodeUrl.value = null
  qrcodeSkeleton.value = true
  orderNo.value = null
  polling.value = null
  cnyAmount.value = null
  isSuccess.value = false
  nprogress.start()
  let curType = type.value

  if (cxka.value) {
    curType += '-cxka'
  }
  const params =
    curType !== 'alipay-face'
      ? {
          amount: amount.value,
          type: curType,
        }
      : {
          amount: amount.value,
          type: curType,
          referString: referString.value,
          mobile: mobile.value,
          alipayAccount: alipayAccount.value,
        }
  UserService.topup(params)
    .then(({ data }) => {
      nprogress.done()
      qrcodeUrl.value = data.url
      // cnyAmount.value = data.cnyAmount;
      generating.value = false
      orderNo.value = data.orderNo
      polling.value = setInterval(() => {
        if (orderNo.value) {
          UserService.topupStatus(orderNo.value).then((res) => {
            if (res.data.status) {
              isSuccess.value = true
              topupSuccessful()
            }
          })
        }
      }, 8000)
    })
    .catch((e) => {
      generating.value = false
      nprogress.done()
      store.commit('SET_TOAST', {
        title: '支付错误',
        data: e.response.data.error.data,
        type: 'error',
      })
    })
}

onMounted(async () => {
  const generateLinks = (amounts) =>
    amounts.map(parseFloat).map((a) => ({
      amount: a,
      link: `${API_URL}/v1/cdk/links/${a}`,
    }))
  fastCdkLink.value = generateLinks([3, 5, 10, 25, 50, 100, 200])
  const { amounts } = (await UserService.cdkLinks()).data
  fastCdkLink.value = generateLinks(amounts)
})
</script>
