<template>
  <nav
    class="bg-white fixed bottom-0 z-50 inset-x-0 shadow-top w-full h-14 py-2 px-8 flex justify-between items-center"
  >
    <router-link class="w-7 h-7" to="/">
      <HomeIconSolid v-if="route.path === '/'" />
      <HomeIcon v-else />
    </router-link>

    <router-link class="w-7 h-7" to="/all-boxes">
      <ArchiveIconSolid v-if="route.path === '/all-boxes'" />
      <ArchiveIcon v-else />
    </router-link>

    <router-link class="w-7 h-7 relative" to="/user-center/account">
      <div
        class="absolute -top-6 left-1/2 -translate-x-1/2 w-16 h-16 rounded-full flex items-center justify-center"
        :class="[
          {
            'bg-yellow-lightest': route.path.includes('/user-center'),
          },
        ]"
      >
        <avatar :imageUrl="user.info.avatar_url" size="56" />
      </div>
    </router-link>

    <router-link class="w-7 h-7" to="/store">
      <ShoppingCartIconSolid v-if="route.path === '/store'" />
      <ShoppingCartIcon v-else />
    </router-link>

    <!-- <div class="w-7 h-7" @click="store.commit('SET_MODAL', 'mobile-menu')">
      <MenuIcon />
    </div> -->

    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="rounded block" type="button">
          <div class="w-7 h-7">
            <MenuIcon />
          </div>
        </MenuButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="origin-bottom-right absolute right-[-30px] bottom-14 mt-2 w-screen rounded-md shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden pt-2"
        >
          <MenuItem v-for="link in links" :key="link.title" v-slot="{ active }">
            <router-link
              v-if="link.click"
              to="#"
              class="flex items-center px-[30px] py-5 text-sm"
              :class="[active ? 'bg-gray-darkest' : '', '']"
              @click.prevent="link.click"
            >
              <img class="mr-8 h-5 w-5 text-black-70" :src="link.icon" />
              <span>{{ link.title }}</span>
            </router-link>
            <router-link
              v-else
              :to="link.to"
              class="relative flex items-center px-[30px] py-5 text-sm"
              :class="[active ? 'bg-gray-darkest' : '', '']"
            >
              <img class="mr-8 h-5 w-5 text-black-70" :src="link.icon" />
              <span>{{ link.title }}</span>
              <div
                v-if="link.to === '/notifications' && unreadNotification"
                class="absolute flex justify-center right-10 top-1/2 -translate-y-1/2 rounded-full bg-red-200 text-red-700 w-6 h-6 text-xs leading-[26px] font-bold"
              >
                {{ unreadNotification }}
              </div>
            </router-link>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
  </nav>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import {
  HomeIcon,
  // UserCircleIcon,
  // BellIcon,
  ArchiveIcon,
  // PlusIcon,
  ShoppingCartIcon,
  MenuIcon,
} from '@heroicons/vue/outline'
import {
  HomeIcon as HomeIconSolid,
  // UserCircleIcon as UserCircleIconSolid,
  // BellIcon as BellIconSolid,
  ArchiveIcon as ArchiveIconSolid,
  ShoppingCartIcon as ShoppingCartIconSolid,
} from '@heroicons/vue/solid'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TopupSVG from '@/assets/my-info/topup.svg'
import BadgeSVG from '@/assets/my-info/badge.svg'
import InfoSVG from '@/assets/my-info/info.svg'
import InvenSVG from '@/assets/my-info/inven.svg'
import DollarSVG from '@/assets/my-info/dollar.svg'
import BellSVG from '@/assets/my-info/bell.svg'
import LogoutSVG from '@/assets/my-info/logout.svg'
import DownloadSVG from '@/assets/my-info/download.svg'
import GiftSVG from '@/assets/my-info/gift.svg'
import { destroyToken } from '@/services/jwt'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const store = useStore()

const user = computed(() => store.state.user)

const unreadNotification = computed(() => store.state.notifications.unread)

function exit() {
  destroyToken()
  store.commit('SET_NOT_LOGIN')
  router.push('sign-in')
}

const links = [
  {
    click: () => store.commit('SET_MODAL', 'topup'),
    to: '#',
    title: t('myinfo.topup'),
    icon: TopupSVG,
  },
  {
    to: '/user-center/account',
    title: t('myinfo.myinf'),
    icon: InfoSVG,
  },
  {
    to: '/user-center/inventory',
    title: t('myinfo.inven'),
    icon: InvenSVG,
  },
  {
    to: '/my-refer',
    title: t('myinfo.promo'),
    icon: BadgeSVG,
  },
  {
    to: '/user-center/trade-history',
    title: t('myinfo.topuphist'),
    icon: DollarSVG,
  },
  {
    to: '/notifications',
    title: t('myinfo.notif'),
    icon: BellSVG,
  },
  {
    to: '/roll',
    title: t('myinfo.roll'),
    icon: GiftSVG,
  },
  {
    to: '/download',
    title: t('navbar.download'),
    icon: DownloadSVG,
  },
  {
    click: exit,
    to: '#',
    title: t('myinfo.exit'),
    icon: LogoutSVG,
  },
]
</script>
