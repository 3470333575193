<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const showModal = computed(() => store.state.modal === 'real-name-notice')
</script>

<template>
  <Modal title="" :visible="showModal" noCloseButton>
    <div class="mx-auto bg-white w-[100%] max-w-[650px] mt-[-50px]">
      <svg
        class="ml-[-100px]"
        width="140%"
        height="125"
        viewBox="0 0 550 125"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4201_28078)">
          <rect width="550" height="125" fill="white" />
          <ellipse cx="275" cy="-4" rx="284" ry="129" fill="#FFF6D7" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M275 76C284.941 76 293 67.9411 293 58C293 48.0589 284.941 40 275 40C265.059 40 257 48.0589 257 58C257 67.9411 265.059 76 275 76ZM299.749 90.2513C306.313 96.815 310 105.717 310 115H275L240 115C240 105.717 243.687 96.815 250.251 90.2513C256.815 83.6875 265.717 80 275 80C284.283 80 293.185 83.6875 299.749 90.2513Z"
            fill="url(#paint0_linear_4201_28078)"
          />
          <circle cx="310" cy="103" r="12" fill="#34D399" stroke="white" stroke-width="2" />
          <rect x="303" y="103.192" width="2" height="8" rx="1" transform="rotate(-45 303 103.192)" fill="white" />
          <rect
            x="308.657"
            y="108.849"
            width="2"
            height="13"
            rx="1"
            transform="rotate(-135 308.657 108.849)"
            fill="white"
          />
          <path
            d="M208 22L209.891 27.1094L215 29L209.891 30.8906L208 36L206.109 30.8906L201 29L206.109 27.1094L208 22Z"
            fill="#34D399"
          />
          <path
            d="M223 64L224.35 67.6495L228 69L224.35 70.3505L223 74L221.65 70.3505L218 69L221.65 67.6495L223 64Z"
            fill="#FF8181"
          />
          <path
            d="M328 65L329.621 69.3795L334 71L329.621 72.6205L328 77L326.379 72.6205L322 71L326.379 69.3795L328 65Z"
            fill="white"
          />
          <path
            d="M330.5 23L332.796 29.2042L339 31.5L332.796 33.7958L330.5 40L328.204 33.7958L322 31.5L328.204 29.2042L330.5 23Z"
            fill="#FF8181"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_4201_28078"
            x1="275"
            y1="40"
            x2="275"
            y2="115"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC700" />
            <stop offset="1" stop-color="#FFF6D7" />
          </linearGradient>
          <clipPath id="clip0_4201_28078">
            <rect width="550" height="125" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <h4 class="text-lg font-bold text-center mt-10">实名认证</h4>

      <h6 class="mt-5">为维护账户资金安全，请完成实名认证。</h6>

      <ButtonLink class="w-full mt-10" to="/real-name">去认证</ButtonLink>
    </div>
  </Modal>
</template>
