export const LOAD_BOX = 'LOAD_BOX';
export const SET_BOX = 'SET_BOX';
export const SET_BOX_LOADING = 'SET_BOX_LOADING';

export const LOAD_USER = 'LOAD_USER';
export const SET_USER = 'SET_USER';

export const SET_STAT = 'SET_STAT';
export const SET_NOT_LOGIN = 'SET_NOT_LOGIN';
export const SET_LOGIN = 'SET_LOGIN';

export const SET_TOAST = 'SET_TOAST';
export const SET_MODAL = 'SET_MODAL';

export const SET_INVENTORY = 'SET_INVENTORY';

export const SET_LANG = 'SET_LANG';

export const SET_PAUSE_DROP = 'SET_PAUSE_DROP';

export const SET_MUTED = 'SET_MUTED';

export const SET_SYS_CONFIG = 'SET_SYS_CONFIG';
