import { createStore } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import box from './box'
import notifications from './notifications'

let sysConfig
try {
  sysConfig = JSON.parse(localStorage.getItem('sys-config'))
  // eslint-disable-next-line
} catch (error) {}

export default createStore({
  modules: {
    box,
    notifications,
  },
  state: {
    app: {
      stat: null,
      config: { currency_symbol: sysConfig?.currency_symbol ?? 'Ł' },
    },
    lang: localStorage.getItem('lang') || 'cn',
    box: {},
    boxLoading: false,
    user: {
      isLogin: !!localStorage.getItem('jwt-token'),
      info: {},
    },
    modal: '',
    loading: false,
    toast: {},
    pauseDrop: localStorage.getItem('pause-drop'),
    muted: !!localStorage.getItem('muted'),
  },
  getters,
  actions,
  mutations,
  strict: true,
  plugins: [],
})
