<template>
  <div :class="[route.meta.isWhite ? 'bg-white' : 'bg-gray-darkest']">
    <EmailVerificationToast />
    <ToastProvider />
    <TopTup />
    <RealNameNoticeModal />
    <AntiAddictionModal />

    <Header />
    <DropList v-if="!isHome && !hideDrop" />
    <Breadcrumb v-if="route.meta.breadCrumbs && !isMobile" />
    <router-view />
    <Footer />
    <SignModal />
    <RuleModal />
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted } from 'vue'
import Header from '@/views/Header'
import DropList from '@/views/Drop/DropList'
import Footer from '@/views/Footer'
import EmailVerificationToast from '@/views/EmailVerification/EmailVerificationToast'
import TopTup from '@/components/Pay/TopUp'
import RealNameNoticeModal from '@/views/RealNameVerification/RealNameNoticeModal'
import AntiAddictionModal from '@/views/Alert/AntiAddictionModal'
import Breadcrumb from '@/components/sections/Breadcrumb'
import ToastProvider from '@/components/ToastProvider'
import RuleModal from '@/components/RuleModal'
import { useRoute } from 'vue-router'
import SignModal from '@/components/SignModal'
import { useStore } from 'vuex'
import { SET_SYS_CONFIG } from '@/store/types'
import { ConfigService } from '@/services/api'
import { useBreakpoints } from '@/hooks'

const { isMobile } = useBreakpoints()

const route = useRoute()
const store = useStore()
let configInterval

async function getConfig() {
  try {
    await ConfigService.getConfigInfo().then(({ data }) => {
      if (data.currency_symbol) {
        store.dispatch(SET_SYS_CONFIG, data)
      }
    })
  } catch (e) {
    console.log(e)
  }
}

onMounted(() => {
  getConfig()
  configInterval = setInterval(getConfig, 20 * 1000)
})

onUnmounted(() => {
  clearInterval(configInterval)
})

const isHome = computed(() => route.path === '/')
const hideDrop = computed(() => route.meta.hideDrop)
</script>
