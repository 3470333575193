/* eslint-disable import/prefer-default-export */
import { computed, onMounted, onUnmounted, ref } from 'vue'

export const useBreakpoints = () => {
  const windowWidth = ref(window.innerWidth)

  // eslint-disable-next-line no-return-assign
  const onWidthChange = () => (windowWidth.value = window.innerWidth)
  onMounted(() => window.addEventListener('resize', onWidthChange))
  onUnmounted(() => window.removeEventListener('resize', onWidthChange))

  const winType = computed(() => {
    if (windowWidth.value < 550) return 'xs'
    if (windowWidth.value >= 550 && windowWidth.value < 1200) return 'md'
    if (windowWidth.value >= 1200) return 'lg'
    return null // This is an unreachable line, simply to keep eslint happy.
  })

  const winWidth = computed(() => windowWidth.value)

  const isMobile = computed(() => winType.value === 'xs')

  return { winWidth, winType, isMobile }
}
