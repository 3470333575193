<template>
  <div class="text-xs sm:text-base py-1 sm:py-4 leading-4 sm:leading-none">
    <h4 v-if="title" class="font-semibold mb-2.5">{{ title }}</h4>
    <div class="flex items-center">
      <div class="mr-1 sm:mr-4">
        <img v-if="type === 'error'" class="w-[30px]" src="@/assets/toast/warning.svg" alt />
        <img v-else class="w-[30px]" src="@/assets/toast/tick.svg" alt />
      </div>
      <div class="w-40 sm:w-[220px]" v-if="data.message">{{ data.message }}</div>
      <div class="w-40 sm:w-[220px]" v-else>未知错误</div>
      <div class="w-40 sm:w-[220px]" v-if="data.tag">
        ，请查阅
        <a class="text-variants-info underline" target="_blank" href="/page/help">帮助</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { errorParser } from '@/utils'

const props = defineProps({
  title: String,
  type: String,
  data: [Object, String],
})

const data = computed(() => errorParser(props.data) ?? {})
</script>
