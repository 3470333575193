export function abbreviateNumber(value) {
  let newValue = value
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't']
    const suffixNum = Math.floor(`${value}`.length / 3)
    let shortValue = ''
    for (let precision = 2; precision >= 1; precision -= 1) {
      shortValue = parseFloat((suffixNum !== 0 ? value / 1000 ** suffixNum : value).toPrecision(precision))
      const dotLessShortValue = `${shortValue}`.replace(/[^a-zA-Z 0-9]+/g, '')
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
  }
  return newValue
}

export function formatSkuPrice(pObj, symbol, tag = '') {
  if (!pObj) return ''
  let sign
  switch (pObj.currency_name) {
    case '人民币':
      sign = symbol
      break
    case '钻石':
      sign = '💎'
      break
    default:
      sign = pObj.currency_name
  }
  const p = tag === 'recycle' ? pObj.recycle_price : pObj.price
  return `${sign} ${p}`
}

export function getPrice(prices, currencyType = 0) {
  if (!prices || prices.length === 0) return 0
  return parseFloat(prices.find(i => i.currency_type === currencyType).price)
}

export function getSkusPriceRange(skus) {
  if (!skus || skus.length <= 0) {
    return {
      min: 0,
      max: 0,
    }
  }

  let max = 0
  let min = Infinity
  skus.forEach(sku => {
    const p = getPrice(sku.prices)
    if (p > max) max = p
    if (p < min) min = p
  })

  return {
    min,
    max,
  }
}

export default abbreviateNumber
