import { createRouter, createWebHistory } from 'vue-router'
import nprogress from 'nprogress/nprogress'
import store from '@/store'
import platform from 'platform'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Root'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: {
          isWhite: true,
          hideDrop: true,
        },
      },
      {
        path: 'all-boxes',
        name: 'AllBoxes',
        component: () => import('@/views/AllBoxes'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'AllBoxes',
              text: '所有箱子',
            },
          ],
        },
      },
      {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/Store'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'Store',
              text: '商城',
            },
          ],
        },
      },
      {
        path: 'user-center',
        redirect: '/user-center/account',
      },
      {
        path: 'user-center/:tabid',
        name: 'UserCenter',
        component: () => import('@/views/users'),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: 'my-refer',
        name: 'MyRefer',
        component: () => import('@/views/MyRefer'),
        meta: {
          isWhite: true,
          loginRequired: true,
        },
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/Notifications'),
        meta: {
          isWhite: true,
        },
      },
      {
        path: 'business-license',
        name: 'BusinessLicense',
        component: () => import('@/views/Footer/BusinessLicense'),
        meta: {
          isWhite: true,
        },
      },
      {
        path: 'page/:page',
        name: 'Page',
        component: () => import('@/views/Page/index'),
      },
      {
        path: 'box/:id',
        name: 'Box',
        component: () => import('@/views/Box'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'AllBoxes',
              text: '所有箱子',
            },
            {
              to: 'Box',
              text: '开箱',
            },
          ],
        },
      },
      {
        path: 'box-new/:id',
        name: 'BoxNew',
        component: () => import('@/views/Box'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'AllBoxes',
              text: '所有箱子',
            },
            {
              to: 'Box',
              text: '开箱',
            },
          ],
        },
      },
      {
        path: 'drop-history/:history',
        name: 'DropHistory',
        component: () => import('@/views/history/DropHistory'),
        meta: {
          isWhite: true,
        },
      },
      {
        path: 'product/:id',
        name: 'Product',
        component: () => import('@/views/Store/Product'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'Store',
              text: '商城',
            },
            {
              to: '',
              text: '商品详情',
            },
          ],
        },
      },
      {
        path: 'product-detail/:id',
        name: 'ProductDetail',
        component: () => import('@/views/Store/Product'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'AllBoxes',
              text: '所有箱子',
            },
            {
              to: '-1',
              text: '开箱',
            },
            {
              to: '',
              text: '商品详情',
            },
          ],
        },
      },
      {
        path: 'roll',
        name: 'Roll',
        component: () => import('@/views/Roll/index'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'Roll',
              text: '福利roll房',
            },
          ],
        },
      },
      {
        path: 'roll/new',
        name: 'RollCreate',
        component: () => import('@/views/Roll/CreateRoll'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'Roll',
              text: '福利roll房',
            },
            {
              to: 'RollCreate',
              text: '新建roll房',
            },
          ],
        },
      },
      {
        path: 'roll/:id',
        name: 'RollRoomDetail',
        component: () => import('@/views/Roll/RollRoomDetail'),
        meta: {
          isWhite: true,
          breadCrumbs: [
            {
              to: 'Roll',
              text: '福利roll房',
            },
            {
              to: 'RollRoomDetail',
              text: 'Roll房',
            },
          ],
        },
      },
      {
        path: 'faq',
        name: 'Faq',
        component: () => import('@/views/Faq'),
        meta: {
          isWhite: true,
        },
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('@/views/Store/Product/checkout'),
        meta: {
          breadCrumbs: [
            {
              to: 'Store',
              text: '商城',
            },
            {
              to: 'Product',
              text: '商品详情',
            },
            {
              to: 'Checkout',
              text: '下单',
            },
          ],
        },
      },
      {
        path: 'verification-notification',
        name: 'EmailVerification',
        component: () => import('@/views/EmailVerification'),
      },
      {
        path: 'real-name',
        name: 'RealNameVerification',
        component: () => import('@/views/RealNameVerification'),
        meta: {
          loginRequired: true,
        },
      },
      {
        path: 'refer/mobile',
        name: 'ReferMobile',
        component: () => import('@/views/Refer/Mobile/index'),
        meta: {
          hideDrop: true,
        },
      },
      {
        path: 'download',
        name: 'Download',
        component: () => import('@/views/Download'),
        children: [
          {
            path: 'android',
            name: 'Android',
            component: () => import('@/views/Download/android'),
          },
          {
            path: 'ios',
            name: 'IOS',
            component: () => import('@/views/Download/ios'),
          },
        ],
      },
      {
        path: 'sign-in',
        name: 'SignIn',
        component: () => import('@/views/SignIn'),
      },
      {
        path: 'sign-up',
        name: 'SignUp',
        component: () => import('@/views/SignUp'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/ForgotPassword'),
      },
      {
        path: 'verification',
        name: 'Verification',
        component: () => import('@/views/ConfirmVerification'),
      },
      {
        path: 'password-reset',
        name: 'Reset',
        component: () => import('@/views/ResetPassword'),
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/views/_404'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.loginRequired && !store.state.user.isLogin) {
    next('/sign-in')
  }
  if (to.query.t) {
    store.dispatch('SET_LOGIN', to.query.t)
    next('/')
  } else {
    if (to.fullPath === '/download') {
      console.log(navigator)
      console.log(platform.os.family)
      const os = platform?.os?.family
      if (os.includes('iOS')) {
        next('/download/ios')
      }
      if (os.includes('Android')) {
        next('/download/android')
      }
      next('/download/ios')
    }
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.fullPath && /^\/ref\/.*/.test(to.fullPath)) {
    window.sessionStorage.setItem('ref', to.fullPath.split('/')[2])
    next('/')
  }
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    nprogress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  nprogress.done()
  window.scrollTo(0, 0)
})

export default router
