<template>
  <span>
    {{ masked }}
  </span>
</template>

<script>
import { onMounted, computed } from 'vue'
import emailRegex from '@/utils/regex'

export default {
  props: {
    text: String,
    show: Boolean,
  },
  setup(props) {
    const masked = computed(() => {
      const ori = props.text
      if (!ori) return ''
      if (props.show) return ori
      const isEmail = emailRegex.test(String(ori).toLowerCase())
      const isPhone = ori.length >= 11
      if (isEmail) {
        const idxAt = ori.indexOf('@')
        // const idxDot = ori.indexOf('.');
        const rv = `${ori[0]}***@${ori[idxAt + 1]}***.***`
        return rv
      }
      if (isPhone) {
        const rv = `${ori.slice(0, 6)}****${ori.slice(10)}`
        return rv
      }
      return '*****'
    })

    onMounted(() => {})

    return {
      masked,
    }
  },
}
</script>
