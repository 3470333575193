<template>
  <RadioGroup class="flex" v-model="select">
    <RadioGroupLabel v-if="title" class="text-sm w-12 pt-3 flex-shrink-0">{{ title }}</RadioGroupLabel>
    <div
      class="text-sm"
      :class="[flex ? 'flex flex-wrap flex-shrink-1 gap-y-2' : `grid grid-cols-4 md:grid-cols-4 lg:grid-cols-${gap}`]"
    >
      <RadioGroupOption
        as="template"
        v-for="(i, k) in tabs"
        :key="i[valueKey] ?? k"
        :value="i[valueKey] ?? k"
        v-slot="{ checked }"
        :disabled="disabled || isDisableItem(i, k) || i.disabled"
      >
        <div
          :class="[
            'group cursor-pointer relative text-center focus:outline-none flex items-center border rounded h-10 leading-10 text-sm sm:flex-0 text-black-50 border-black-50 whitespace-nowrap px-[2px] ml-2',
            checked ? 'border-[#ff7c00] border-2 px-px text-[#ff7c00]' : '',
            disabled || isDisableItem(i, k) || i.disabled ? 'opacity-50 border-dashed' : 'hover:text-[#ff7c00]',
          ]"
          @click="() => handleDeselect(checked, disabled || isDisableItem(i, k) || i.disabled, enableDeselect)"
        >
          <div
            id="tooltip-light"
            role="tooltip"
            :class="[
              'inline-block absolute invisible z-10 py-2 px-3 top-[-100%] left-[50%] -translate-x-1/2 text-xs font-medium text-white bg-black rounded-lg shadow-sm opacity-0 ease-in duration-100 group-hover:delay-500 tooltip group-hover:visible group-hover:opacity-100',
              (formatText?.(i[textKey] ?? i) ?? i[textKey] ?? i).length > 1 ? 'text-xs' : '',
            ]"
          >
            <slot name="tooltip" :item="i">
              <span>{{ formatText?.(i[textKey] ?? i) ?? i[textKey] ?? i }}</span>
            </slot>
          </div>
          <slot :item="i">
            <p class="px-3">{{ formatText?.(i[textKey] ?? i) ?? i[textKey] ?? i }}</p>
          </slot>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script setup>
import { ref, watch } from 'vue'
import { RadioGroupOption, RadioGroupLabel, RadioGroup } from '@headlessui/vue'

const props = defineProps({
  title: String,
  disabled: Boolean,
  isDisableItem: { type: Function, default: () => false },
  enableDeselect: Boolean,
  tabs: [Array, Set],
  active: Number,
  flex: Boolean,
  valueKey: {
    type: String,
    default: 'value',
  },
  textKey: {
    type: String,
    default: 'text',
  },
  formatText: Function,
  gap: {
    type: Number,
    default: 6,
  },
  modelValue: [String, Number, Object],
})

const select = ref(props.active)

const emit = defineEmits(['onSelect', 'update:modelValue'])

function handleDeselect(checked, disabled, enableDeselect) {
  if (disabled) return
  if (checked && enableDeselect) {
    select.value = null
  }
}

watch(
  () => select.value,
  id => {
    emit('onSelect', id)
    emit('update:modelValue', props.tabs[id])
  }
)
</script>
