<template>
  <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.5866 1.79256C28.8117 1.28509 30.1883 1.28509 31.4134 1.79256L47.032 8.262C48.2572 8.76947 49.2305 9.74284 49.738 10.968L56.2074 26.5866C56.7149 27.8117 56.7149 29.1883 56.2074 30.4134L49.738 46.032C49.2305 47.2572 48.2572 48.2305 47.032 48.738L31.4134 55.2074C30.1883 55.7149 28.8117 55.7149 27.5866 55.2074L11.968 48.738C10.7428 48.2305 9.76947 47.2572 9.262 46.032L2.79256 30.4134C2.28509 29.1883 2.28509 27.8117 2.79256 26.5866L9.262 10.968C9.76947 9.74284 10.7428 8.76947 11.968 8.262L27.5866 1.79256Z"
      fill="#E5E7EB"
    />
    <path
      d="M9.99984 18.3327C12.301 18.3327 14.3843 17.3999 15.8924 15.8919C17.4004 14.3838 18.3332 12.3005 18.3332 9.99935C18.3332 7.69818 17.4004 5.61485 15.8924 4.10679C14.3843 2.59876 12.301 1.66602 9.99984 1.66602C7.69867 1.66602 5.61534 2.59876 4.10728 4.10679C2.59925 5.61485 1.6665 7.69818 1.6665 9.99935C1.6665 12.3005 2.59925 14.3838 4.10728 15.8919C5.61534 17.3999 7.69867 18.3327 9.99984 18.3327Z"
      fill="#FFC700"
      stroke="#FFC700"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="M10.0217 5L10.0086 15M5 10H15"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 30.152C15 30.1417 15.0089 30.1361 15.0264 30.1348L15 30.152ZM15.0264 30.1348C15.5255 30.0974 22.9806 33.6338 26.7722 35.4684L45 23.6962L33.6076 18L15.0264 30.1348ZM30.5696 28.6329C32.8766 28.6329 34.7468 27.6128 34.7468 26.3545C34.7468 25.0961 32.8766 24.076 30.5696 24.076C28.2626 24.076 26.3924 25.0961 26.3924 26.3545C26.3924 27.6128 28.2626 28.6329 30.5696 28.6329Z"
      fill="#9CA3AF"
    />
    <path d="M15 33.1904V31.2916L26.7722 36.6081L45 24.8359V26.7347L26.7722 38.5068L15 33.1904Z" fill="#9CA3AF" />
    <path d="M15 36.2275V34.7085L26.7722 39.6452L45 27.873V29.7718L26.7722 41.5439L15 36.2275Z" fill="#9CA3AF" />
  </svg>
</template>
