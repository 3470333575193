<template>
  <div class="container text-center py-10 text-sm text-gray">
    <div class="divider w-full h-px mb-7" />
    <div class="space-x-2">
      <span>©2020海南省樱珠网络科技有限公司</span>
      <a
        class="text-blue-600"
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        rel="noopener noreferrer"
      >
        琼ICP备2021000070号-3
      </a>
      <span>经营许可证编号：琼B2-20210563</span>
    </div>
    <div class="space-x-2">
      <span>统一社会信用代码：91460000MA5TRELMID</span>
      <router-link class="text-blue-600" to="business-license">营业执照</router-link>
    </div>
  </div>
</template>

<style scoped>
.divider {
  background: linear-gradient(-90deg, rgba(39, 42, 44, 0) 0, #666 49%, rgba(39, 42, 44, 0) 100%);
}
</style>
