<template>
  <div class="flex items-center space-x-2.5 mb-2.5" v-if="user.isLogin">
    <label class="flex-shrink-0" form="otp">促销码:</label>
    <Input type="text" placeholder="促销码" v-model="parentString" />
    <Button @onclick="updateRef" class="flex-shrink-0 w-[100px]">更新</Button>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { UserService } from '@/services/api'

import Input from '@/components/_basic/Input'

const store = useStore()
const parentString = ref('')
const user = computed(() => store.state.user)

async function updateRef() {
  const newRef = parentString.value
  try {
    await UserService.updateParent(newRef)
    store.commit('SET_TOAST', {
      title: '更新成功',
      data: '促销码修改成功',
      type: 'success',
    })
  } catch (e) {
    // store.commit('SET_TOAST', {
    //   title: '更新失败',
    //   data: '请确认促销码是否正确',
    //   type: 'error',
    // });
    store.commit('SET_TOAST', {
      title: '更新失败',
      data: e.response,
      type: 'error',
    })
  }
}

onMounted(() => {
  store.dispatch('LOAD_USER')
  parentString.value = store.state.user.info.parent_refer_string
})
</script>
