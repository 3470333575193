import { NotificationService } from '@/services/api'

export default {
  namespaced: true,
  state: () => ({
    notificationList: [],
    loading: false,
    unread: 0,
  }),
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload
    },
    setNotificationList: (state, payload) => {
      state.notificationList = payload
    },
    setUnread: (state, payload) => {
      state.unread = payload
    },
  },
  actions: {
    getNotificationList({ commit, state }) {
      commit('setLoading', true)
      NotificationService.getNotifications()
        .then(({ data }) => {
          commit('setNotificationList', data)
          const unread = state.notificationList.reduce((a, b) => (!b.read_at ? a + 1 : a), 0)
          commit('setUnread', unread)
        })
        .catch()
        .finally(() => {
          commit('setLoading', false)
        })
    },
    async readNotification({ commit, state }, payload) {
      try {
        await NotificationService.readNotification(payload)
        const unread = state.notificationList.reduce((a, b) => (!b.read_at ? a + 1 : a), 0)
        commit('setUnread', unread)
      } catch (e) {
        console.error(e)
      }
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
    notificationList(state) {
      return state.notificationList
    },
    unread(state) {
      return state.unread
    },
  },
}
