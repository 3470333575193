<template>
  <div class="min-w-[140px] h-14 p-1 box-border rounded-lg text-black cursor-pointer transition-all flex bg-[#e7e5e9]">
    <div class="min-w-[100px] flex-shrink-0" v-if="label">{{ label }}</div>
    <div class="w-full h-full px-1 relative flex items-center justify-center overflow-x-auto">
      <ul class="relative flex w-full list-none select-none" ref="slideChipList">
        <li
          class="py-2 px-5 list-none flex-shrink-0 transition-all z-10 select-none text-[#73717C] text-sm sm:text-md flex-grow"
          v-for="(tab, i) in options"
          :key="tab.value"
          :class="{ '!text-black': tab.value === iVal }"
          :ref="
            el => {
              chipRefs[i] = el
            }
          "
        >
          <button @click.stop="iVal = tab.value">
            <slot :name="tab.value" :item="tab" />

            <span v-if="!$slots[tab.value]" class="">{{ tab.mobileName }}</span>
          </button>
        </li>
        <div
          class="h-11 w-16 bg-white absolute top-[-4px] transition-all rounded-lg z-0"
          :style="{ left: `${barLeftOffset}px`, width: `${barWidth}px` }"
        ></div>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUpdate } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: String,
  options: {
    type: Array,
  },
  label: String,
})

const iVal = ref(props.modelValue)
const barLeftOffset = ref(0)
const barWidth = ref(55)

const slideChipList = ref(null)
const chipRefs = ref([])

onBeforeUpdate(() => {
  chipRefs.value = []
})

const adjustBar = val => {
  const idx = props.options.findIndex(i => i.value === val)
  const listOffset = slideChipList.value.getBoundingClientRect()
  const selectedOffset = chipRefs.value[idx].getBoundingClientRect()
  barLeftOffset.value = selectedOffset.left - listOffset.left
  barWidth.value = selectedOffset.width
}

onMounted(() => {
  adjustBar(props.modelValue)
})

watch(
  () => props.modelValue,
  val => {
    iVal.value = val
  }
)

watch(
  () => iVal.value,
  val => {
    emit('update:modelValue', val)
    adjustBar(val)
  }
)

// export default {
//   name: 'SlideChipSelect',
//   props: {
//     value: Object,
//     options: {
//       type: Array,
//     },
//     label: String,
//   },
//   data() {
//     return {
//       iVal: this.value,
//       barLeftOffset: 0,
//       barWidth: 55,
//     }
//   },
//   computed: {},
//   mounted() {
//     this.adjustBar(this.value)
//   },
//   watch: {
//     value(val) {
//       this.iVal = val
//     },
//     iVal(val) {
//       this.$emit('input', val)
//       this.adjustBar(val)
//     },
//   },
//   methods: {
//     isActive(value) {
//       return this.iVal?.value === value
//     },
//     adjustBar(val) {
//       // const bar = this.$refs.slideBar.getBoundingClientRect()
//       const listOffset = this.$refs.slideChipList.getBoundingClientRect()
//       const selectedOffset = this.$refs[`slideChip${val.value}`][0].getBoundingClientRect()
//       this.barLeftOffset = selectedOffset.left - listOffset.left
//       this.barWidth = selectedOffset.width
//     },
//   },
// }
</script>
