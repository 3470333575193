<template>
  <div
    v-if="unverified"
    class="fixed top-8 left-1/2 -translate-x-1/2 z-[100] shadow-sm bg-white px-5 sm:px-12 py-1 flex items-center justify-between rounded-md animate-fade-in sm:w-auto w-[90%]"
  >
    <div>
      <div class="pb-2 font-extrabold">LuckSec 邮箱验证</div>
      <div class="text-sm" v-if="!emailSent">
        您还没有验证邮箱，这将会影响您的正常使用，
        <button class="text-[#3358ff] underline mr-1 inline" :disabled="loading" @click="handleEmailVerification">
          点击这里
        </button>
        立刻验证

        <Spinner v-if="loading" class="text-black ml-2" />
      </div>
      <div class="text-sm flex" v-if="emailSent">
        已发送验证邮件，请前往邮箱点击验证链接
      </div>
    </div>
    <EmailVerificationSvg class="ml-1 sm:ml-10 flex-shrink-0" />
  </div>
</template>

<script setup>
import EmailVerificationSvg from '@/components/svgs/EmailVerificationSvg'
import { UserService } from '@/services/api'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import Spinner from '@/components/_basic/Spinner'

const store = useStore()
const emailSent = ref(false)
const loading = ref(false)

const unverified = computed(() => {
  const signed = sessionStorage.getItem('signed')

  return (
    store.state.user.info.login_type === 'email' &&
    !store.state.user.info.email_verified &&
    !(
      store.state.user.isLogin &&
      store.state.user.info.agreements === false &&
      (!signed || signed === 'false' || signed === false)
    )
  )
})

const handleEmailVerification = () => {
  loading.value = true
  UserService.emailVerification()
    .then(() => {
      emailSent.value = true
    })
    .catch(() => {})
    .finally(() => {
      loading.value = false
    })
}
</script>
