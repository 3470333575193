<template>
  <div class="overflow-hidden z-0 pt-2 sm:pt-20 pb-4 sm:pb-6 relative">
    <!-- <Bg class="bg__svg" /> -->
    <div class="flex" v-if="filteredDrops.length">
      <Drop v-for="(v, i) in filteredDrops" :style="'order:-' + i" :key="v.id" :drop="v"></Drop>
    </div>
    <div v-else class="flex">
      <div
        v-for="i in new Array(15)"
        :key="i"
        class="w-[100px] h-[100px] sm:w-[180px] sm:h-[180px] m-1 sm:m-2.5 pb-1 flex-shrink-0"
      >
        <Skeleton class="" height="100%"></Skeleton>
      </div>
    </div>
    <div v-if="onDev && !isMobile" class="pause">
      <div class="pause-box" :class="{ paused: paused }" @click="pause">
        <span class="pause-text">实时掉落</span>
        <span class="pause-btn">
          <PlayIcon v-if="paused" />
          <PauseIcon v-else />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BoxService } from '@/services/api'
import Drop from './Drop'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'
import { PlayIcon, PauseIcon } from '@heroicons/vue/outline'
import { useBreakpoints } from '@/hooks'

const { isMobile } = useBreakpoints()

const MAX_LENGTH = 35

const store = useStore()

const drops = ref([])
const polling = ref(null)
const paused = computed(() => store.state.pauseDrop)

const onDev = computed(() => window.location.hostname === 'localhost')

const fetchDrops = () => {
  BoxService.getLiveDrop().then(x => {
    store.commit('SET_STAT', x.data)
    Object.values(x.data).forEach(o => {
      if (!drops.value.some(v => v.id === o.id)) {
        drops.value.push(o)
      }
    })
    if (drops.value.length > MAX_LENGTH) {
      drops.value.splice(0, drops.value.length - MAX_LENGTH)
    }
  })
}

const pollData = () => {
  polling.value = setInterval(() => {
    fetchDrops()
  }, 3500)
}

const filteredDrops = computed(() => drops.value)

function pause() {
  store.dispatch('SET_PAUSE_DROP', !paused.value)
  if (paused.value) {
    clearInterval(polling.value)
  } else {
    pollData()
  }
}

// watch(() => best.value, () => {
//   drops.value.splice(0, drops.value.length);
//   fetchDrops();
// });

onUnmounted(() => {
  clearInterval(polling.value)
})

onMounted(() => {
  if (!store.state.pauseDrop) {
    pollData()
  }
  fetchDrops()
})
</script>

<style scoped>
/* .drop-i:last-child {
  animation: show 1s normal forwards ease-in-out;
}

.drop-i:not(last-child) {
  animation: fade 1s normal forwards ease-in-out;
} */

/* @keyframes fade {
  0% {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

@keyframes show {
  0% {
    margin-left: -183px;
    opacity: 0;
    transform: perspective(800px) rotateY(-90deg) translateZ(0);
  }
  to {
    margin-left: 0;
    opacity: 1;
    transform: perspective(800px) rotateY(0deg) translateZ(0);
  }
} */

.pause {
  position: absolute;
  right: 0;
  bottom: 0;
}
.pause-box {
  width: 130px;
  margin-left: auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pause-box.paused .pause-text::before {
  animation: none;
}

.pause-text {
  margin-right: 15px;
  font-size: 13px;
  color: #000;
  position: relative;
}

.pause-text::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -14px;
  width: 6px;
  height: 6px;
  background-color: #f11c53;
  box-shadow: 0 0 15px rgb(241 28 83 / 91%);
  animation: blinking 0.75s infinite alternate;
  border-radius: 50%;
  transform: translateY(-50%);
}

.pause-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #4b4e68;
  font-size: 12px;
}

@keyframes blinking {
  to {
    opacity: 0;
  }
}
</style>
