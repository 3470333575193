<template>
  <div>
    <MobileNav v-if="isMobile" />
    <Navbar v-else :solid="isHome" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import MobileNav from './Mobile'
import Navbar from './Navbar'
import { useBreakpoints } from '@/hooks'

const route = useRoute()

const { isMobile } = useBreakpoints()

const isHome = computed(() => route.path === '/')
</script>
