import { useStore } from 'vuex';
import { watch, defineComponent, h } from 'vue';
import Toast from './Toast';
import { ElNotification } from 'element-plus';

const ToastProvider = defineComponent(() => {
  const store = useStore();

  watch(() => store.state.toast, (val) => {
    ElNotification({
      message: h(Toast, val),
      customClass: `lk-notification ${val.type}`,
    });
  });
});

export default ToastProvider;
