<template>
  <div class="scroll" id="inventory-scroll" ref="inventoryScroll">
    <div class="chevron"></div>
    <div class="chevron"></div>
    <div class="chevron"></div>
    <span class="text">More</span>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'

const myTimeOut = ref(null)
const scrollTick = ref(false)
const inventoryScroll = ref(null)

const emit = defineEmits(['onMore'])

const onScroll = () => {
  if (!inventoryScroll) return
  const scroll = inventoryScroll.value
  if (
    !scrollTick.value &&
    scroll.getBoundingClientRect().bottom <= window.innerHeight - 200 &&
    scroll.getBoundingClientRect().top >= 0
  ) {
    scrollTick.value = true
    if (myTimeOut) myTimeOut.value = null
    myTimeOut.value = setTimeout(() => {
      scrollTick.value = false
      emit('onMore')
    }, 1000)
  }
  if (
    scrollTick.value &&
    (scroll.getBoundingClientRect().bottom > window.innerHeight - 200 || scroll.getBoundingClientRect().top < 0)
  ) {
    scrollTick.value = false
    clearTimeout(myTimeOut.value)
    myTimeOut.value = null
  }
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<style lang="css" scoped>
/* scroll down icon */
.scroll {
  position: relative;
  width: 28px;
  height: 80px;
  margin: 30px auto 60px;
}

.chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #222;
}

.chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;
  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.text {
  display: block;
  /* font-family: $font-oxa; */
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: 0.3;
  animation: pulse 1s linear alternate infinite;
  position: absolute;
  bottom: -10px;
  left: -5px;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}
</style>
