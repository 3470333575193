import {
  LOAD_BOX,
  SET_BOX,
  SET_TOAST,
  SET_USER,
  SET_NOT_LOGIN,
  SET_LOGIN,
  SET_MODAL,
  SET_INVENTORY,
  SET_STAT,
  SET_LANG,
  SET_BOX_LOADING,
  SET_PAUSE_DROP,
  SET_MUTED,
  SET_SYS_CONFIG,
} from './types';

export default {
  [SET_BOX](state, payload) {
    state.box = payload;
  },
  [SET_BOX_LOADING](state, payload) {
    state.boxLoading = payload;
  },
  [LOAD_BOX](state) {
    state.box = {};
    state.dice = {
      started: false,
    };
  },

  [SET_USER](state, payload) {
    state.user.info = payload;
    state.user.isLogin = true;
  },

  [SET_NOT_LOGIN](state) {
    state.user.info = {};
    state.user.isLogin = false;
  },

  [SET_LOGIN](state) {
    state.user.isLogin = true;
  },

  [SET_TOAST](state, payload) {
    state.toast = payload;
  },

  [SET_MODAL](state, payload) {
    state.modal = payload;
  },
  [SET_INVENTORY](state, payload) {
    state.inventory = payload;
  },
  [SET_STAT](state, payload) {
    state.app.stat = payload;
  },
  [SET_SYS_CONFIG](state, payload) {
    state.app.config = payload;
  },

  [SET_LANG](state, payload) {
    state.lang = payload;
  },

  [SET_PAUSE_DROP](state, payload) {
    state.pauseDrop = payload;
  },

  [SET_MUTED](state, payload) {
    state.muted = payload;
  },
};
