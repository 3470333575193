import { BoxService, UserService } from '../services/api';
import {
  LOAD_BOX,
  SET_BOX,
  LOAD_USER,
  SET_USER,
  SET_NOT_LOGIN,
  SET_LOGIN,
  SET_LANG,
  SET_BOX_LOADING,
  SET_PAUSE_DROP,
  SET_MUTED,
  SET_SYS_CONFIG,
} from './types';
import { saveToken } from '../services/jwt';

export default {
  async [LOAD_BOX](context, payload) {
    context.commit(SET_BOX_LOADING, true);
    context.commit(LOAD_BOX);
    const { data } = await BoxService.getBoxById(payload.id);
    context.commit(SET_BOX, data);
    context.commit(SET_BOX_LOADING, false);
  },

  async [LOAD_USER](context) {
    try {
      const { data } = await UserService.getUserInfo();
      context.commit(SET_USER, data);
    } catch (e) {
      if (e.response && (e.response.status === 401 || e.response.status === 403)) {
        context.commit(SET_NOT_LOGIN);
      }
    }
  },

  [SET_LOGIN](context, payload) {
    saveToken(payload);
    context.commit(SET_LOGIN, payload);
    context.dispatch(LOAD_USER);
  },

  [SET_LANG](context, payload) {
    context.commit(SET_LANG, payload);
    localStorage.setItem('lang', payload);
  },

  [SET_SYS_CONFIG](context, payload) {
    context.commit(SET_SYS_CONFIG, payload);
    localStorage.setItem('sys-config', JSON.stringify(payload));
  },

  [SET_PAUSE_DROP](context, payload) {
    context.commit(SET_PAUSE_DROP, payload);
    localStorage.setItem('pause-drop', payload);
  },

  [SET_MUTED](context, payload) {
    context.commit(SET_MUTED, payload);
    if (!payload) {
      localStorage.removeItem('muted');
    } else {
      localStorage.setItem('muted', true);
    }
  },
};
