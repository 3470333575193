<script setup>
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { destroyToken } from '@/services/jwt'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const storageValueName = 'topup-overflow'

const totalTopup = computed(() => {
  const TotalTopupString = store.state.user?.info?.total_topup
  return TotalTopupString ? parseFloat(TotalTopupString) : 0
})

const totalTopupIntegerInWan = computed(() => {
  return Math.trunc(totalTopup.value / 10000)
})

watch(
  () => totalTopup.value,
  (newVal) => {
    if (newVal) {
      const newAmountInWan = parseFloat(newVal) / 10000
      const thresholdString = localStorage.getItem(storageValueName)
      const threshold = thresholdString ? parseFloat(thresholdString) : 0
      if (newAmountInWan > threshold + 1) {
        store.commit('SET_MODAL', 'anti-addiction')
      }
    }
  }
)

const showModal = computed(() => store.state.modal === 'anti-addiction')

const logout = () => {
  localStorage.setItem(storageValueName, totalTopupIntegerInWan.value)
  store.commit('SET_MODAL', '')
  destroyToken()
  store.commit('SET_NOT_LOGIN')
  router.push('/')
}
</script>

<template>
  <Modal title="提示" size="md" :visible="showModal" noCloseButton>
    <div class="mx-auto bg-white w-[100%] max-w-[650px] text-left">
      <p class="mt-5 mb-2">尊敬的用户：</p>

      <p class="">
        您的充值金额已经达到{{
          totalTopupIntegerInWan
        }}万人民币，Lucksec在此温馨提醒您，请您在充分了解消费方式后理性使用。
      </p>

      <p class="mt-2">
        本平台不向未满8周岁未成年人提供服务、销售盲盒，8周岁及以上未成年人想要购买盲盒，应当承诺并证明已依法取得相关监护人同意，本平台已通过显著方式提示8周岁及以上未成年人购买盲盒需取得相关监护人同意。
      </p>

      <p class="mt-3 text-gray-500">
        为倡议用户树立理性消费价值观，在充值每满1万后，Lucksec会暂时将您的账号弹出，<span class="text-yellow-600">
          如要继续使用，请您重新登录 </span
        >。
      </p>

      <p></p>

      <Button class="w-full mt-10" @click="logout">确认退出</Button>
    </div>
  </Modal>
</template>
