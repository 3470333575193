<template>
  <div class="relative flex items-center justify-center" :style="`width:${size}px;height:${size}px;`">
    <img class="rounded-full border-2 border-yellow h-full w-full object-center" :src="getUrl" />
    <div
      class="bg-yellow bg-opacity-50 rounded-full w-2/5 h-2/5 absolute right-0 top-0 flex items-center justify-center"
      v-if="showPremiumBadge"
    >
      💎
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import defaultAvatar from '@/assets/user.png'

const props = defineProps({
  imageUrl: String,
  showPremiumBadge: Boolean,
  size: String,
})

const getUrl = computed(() => props.imageUrl ?? defaultAvatar)
</script>
