<template>
  <nav
    class="bg-white border-yellow border-b-4 fixed top-0 z-50 inset-x-0"
    :class="{
      'bg-white shadow': shrink,
      'bg-transparent border-none': solid && !shrink,
      shadow: !solid,
    }"
  >
    <div class="w-full max-w-[1432px] mx-auto px-2 sm:px-6 lg:px-8 flex">
      <div class="relative flex items-center justify-between h-16 flex-1">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            @click="showMenu = !showMenu"
          >
            <span class="sr-only">Open main menu</span>
            <MenuIcon v-if="!showMenu" class="w-8" />
            <XIcon v-else class="w-8" />
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <router-link to="/">
              <img class="block h-8 w-auto" alt="lucksec" src="@/assets/brand_s.png" />
            </router-link>
          </div>
          <div class="hidden sm:block sm:ml-auto">
            <div class="flex space-x-4">
              <router-link
                v-for="link in Links"
                :to="link.to"
                :key="link.name"
                class="text-black-70 hover:border-yellow hover:text-black-50 px-1 pt-1 border-b-2 no-underline font-bold"
                :class="$route.name === link.name ? 'border-yellow' : 'border-transparent'"
              >{{ link.title }}</router-link
              >
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="rounded block" type="button">
                    <div
                      class="text-black-70 border-transparent hover:border-yellow hover:text-black-50 px-1 pt-1 border-b-2  no-underline font-bold"
                    >
                      {{ t('navbar.language.title') }}
                    </div>
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-36 px-2 pb-2 rounded-md shadow bg-white ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden pt-2"
                  >
                    <MenuItem v-slot="{ active }">
                      <router-link
                        @click.prevent="changeLanguage('en')"
                        class="px-2 py-1 flex"
                        to="#"
                        :class="[active ? 'bg-gray-darkest' : '']"
                      >
                        <span class="pr-4">🇬🇧</span> {{ t('navbar.language.en') }}
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <router-link
                        @click.prevent="changeLanguage('cn')"
                        class="px-2 py-1 flex"
                        to="#"
                        :class="[active ? 'bg-gray-darkest' : '']"
                      >
                        <span class="pr-4">🇨🇳</span> {{ t('navbar.language.cn') }}
                      </router-link>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
              <router-link
                v-if="!user.isLogin"
                :to="{ name: 'SignIn' }"
                class="text-black-70 hover:border-yellow hover:text-black-50 px-1 pt-1 border-b-2 no-underline font-bold"
                :class="$route.name === 'SignIn' ? 'border-yellow' : 'border-transparent'"
              >{{ t('navbar.auth') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="user.isLogin"
        class="absolute inset-y-0 right-0 flex items-center pr-5 sm:static sm:inset-auto sm:ml-6 sm:pr-0 h-[64px]"
      >
        <MyInfo />
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div
      v-if="showMenu"
      class="sm:hidden"
      id="mobile-menu"
      :class="{ 'bg-white': !showMenu, 'bg-[#fece47]': solid && showMenu }"
    >
      <div class="pt-2 pb-4 space-y-1">
        <router-link
          v-for="link in Links"
          :to="link.to"
          :key="link.name"
          class="text-black-70 hover:border-yellow hover:text-black-70 block pl-3 pr-4 py-2 border-l-4 no-underline font-bold"
          :class="$route.name === link.name ? 'border-yellow' : 'border-transparent'"
        >{{ link.title }}</router-link
        >
        <router-link
          v-if="!user.isLogin"
          :to="{ name: 'SignIn' }"
          class="text-black-70 hover:border-yellow hover:text-black-70 block pl-3 pr-4 py-2 border-l-4 no-underline font-bold"
          :class="$route.name === 'SignIn' ? 'border-yellow' : 'border-transparent'"
        >{{ t('navbar.auth') }}
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { getToken } from '@/services/jwt'
import MyInfo from './MyInfo'
import { SET_LANG } from '@/store/types'
import router from '../../../router'

defineProps({
  solid: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const { t, locale } = useI18n()

const Links = [
  {
    to: '/all-boxes',
    title: t('navbar.all'),
    name: 'AllBoxes',
  },
  {
    to: '/store',
    title: t('navbar.store'),
    name: 'Store',
  },
  {
    to: '/roll',
    title: t('navbar.roll'),
    name: 'Roll',
  },
  {
    to: '/faq',
    title: t('navbar.faq'),
    name: 'Faq',
  },
  {
    to: '/download',
    title: t('navbar.download'),
    name: 'Download',
  },
]

const scrollPosition = ref()
const shrink = ref(false)
const user = computed(() => store.state.user)

const showMenu = ref(false)

const updateScroll = () => {
  scrollPosition.value = window.scrollY
}

function changeLanguage(language) {
  store.dispatch(SET_LANG, language)
  // eslint-disable-next-line no-underscore-dangle
  locale._setter(language)
  // eslint-disable-next-line no-restricted-globals
  location.reload()
}

onMounted(() => {
  if (user.value.isLogin || getToken()) {
    store.dispatch('LOAD_USER')
  }
  window.addEventListener('scroll', updateScroll)
})

watch(
  () => scrollPosition.value,
  val => {
    shrink.value = val > 50
  }
)

watch(
  () => router.currentRoute.value,
  val => {
    showMenu.value = false
    console.log(val)
    // if (val.fullPath !== '/real-name') {
    //   if (user.value.isLogin && user.value.info?.real_name_verified === false) {
    //     store.commit('SET_MODAL', 'real-name-notice')
    //   }
    // }
  }
)
</script>
