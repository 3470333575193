import axios from 'axios'
import { getToken, saveToken } from './jwt'
import { API_URL } from '../config'

const testUrl = localStorage.getItem('force_api_url')

const ApiService = {
  init() {
    if (testUrl) {
      axios.defaults.baseURL = testUrl
    } else {
      axios.defaults.baseURL = API_URL
    }
    axios.interceptors.request.use(request => {
      if (getToken() != null) {
        // eslint-disable-next-line no-param-reassign
        request.headers.authorization = `Bearer ${getToken()}`
      }
      return request
    })
    axios.interceptors.response.use(
      response => {
        const token = response.headers.authorization
        if (token) {
          saveToken(token)
        }
        return response
      },
      error => {
        if (!error.response) {
          throw error
        }
        switch (error.response.status) {
          default:
            throw error
        }
      }
    )
  },

  query(resource, params) {
    return axios.get(resource, params)
  },

  get(path) {
    return axios.get(path)
  },

  post(path, params) {
    return axios.post(path, params)
  },

  update(path, params) {
    return axios.put(path, params)
  },

  put(path, params) {
    return axios.put(path, params)
  },
}

export default ApiService

export const BoxService = {
  getAllBoxes() {
    return ApiService.get('/v1/boxes')
  },
  getHomeBoxes() {
    return ApiService.get('/v1/boxes?type=home')
  },
  getBoxById(id) {
    return ApiService.get(`/v1/boxes/${id}`)
  },
  buyBoxById(id, params) {
    return ApiService.post(`/v1/boxes/${id}`, params)
  },
  getLiveDrop() {
    return ApiService.get('/v1/live-drop')
  },
}

export const StoreService = {
  getAllProducts() {
    return ApiService.get('/v1/products')
  },
  getAllProductsV2(params) {
    return ApiService.get(`/v2/products?${params}`)
  },
  getProductById(id) {
    return ApiService.get(`/v1/products/${id}`)
  },
  buyProduct(params) {
    return ApiService.post('/v1/products/buy', params)
  },
}

export const UserService = {
  // user auth
  register(params) {
    return ApiService.post('/v1/auth/register', params)
  },
  login(params) {
    return ApiService.post('/v1/auth/login', params)
  },
  forgot(params) {
    return ApiService.post('/v1/auth/password/forgot', params)
  },
  resetPassword(params) {
    return ApiService.post('/v1/auth/password/forgot/reset', params)
  },
  getResetToken(params) {
    return ApiService.get(`/v1/auth/password/forgot?token=${params}`)
  },
  getCode(phone, ticket, randStr) {
    return ApiService.post('/v1/auth/verification', { mobile: phone, ticket, randStr })
  },
  getUserInfo() {
    return ApiService.get('/v1/user/me')
  },
  // refer
  /**
   * @description 获取推广计划说明
   *
   * @returns
   */
  getReferInfo() {
    return ApiService.get('/v1/info/refer')
  },
  setReferCode(code) {
    return ApiService.put('/v1/user/refer-string', {
      refer_string: code,
    })
  },
  getReferHistory() {
    return ApiService.get('/v1/history/refers')
  },
  getReferQrcode() {
    return ApiService.get('/v1/user/refer-qrcode')
  },
  // inventory
  getInventoryBoxes() {
    return ApiService.get('/v1/inventories/boxes')
  },
  getInventoryItems() {
    return ApiService.get('/v1/inventories/items')
  },
  getInventoryActiveNextPage(url) {
    return ApiService.get(url)
  },
  openInventoryBox(id) {
    return ApiService.post(`/v1/inventories/boxes/${id}/open`)
  },
  recycleItem(id, params) {
    return ApiService.post(`/v1/inventories/items/${id}/sell`, params)
  },
  recycleItems(ids) {
    return ApiService.post('/v1/inventories/items/sell/multiple', { user_sku_ids: ids })
  },
  useItem(id) {
    return ApiService.post(`/v1/inventories/items/${id}/sell/express`)
  },
  // shipments
  shipItem(id, params) {
    return ApiService.post(`/v1/inventories/items/${id}/ship`, params)
  },
  exchangeItem(id, targetSkuHashName) {
    return ApiService.post(`/v1/inventories/items/${id}/exchange`, { sku_hash_name: targetSkuHashName })
  },
  getAllOrders() {
    return ApiService.get('/v1/shipments')
  },
  getOrdersByPage(page = 1, status = '') {
    return ApiService.get(`/v1/shipments?page=${page}${status ? `&stage=${status}` : ''}`)
  },
  getOrdersNextPage(url) {
    return ApiService.get(url)
  },
  getAllHistoryAddress() {
    return ApiService.get('/v1/shipments/history-address')
  },
  // top up
  topup(params) {
    return ApiService.post('/v1/topup', params)
  },
  topupStatus(orderNo) {
    return ApiService.get(`/v1/topup/${orderNo}`)
  },
  topupCdk(params) {
    return ApiService.post('/v1/cdk/topup', params)
  },
  cdkLinks() {
    return ApiService.get('/v1/cdk/links')
  },
  // history
  getHistory(id) {
    return ApiService.get(`/v1/live-drop/${id}`)
  },
  getTransactionHistory(page = 1) {
    return ApiService.get(`/v1/payment/history?page=${page}`)
  },
  updateParent(newRef) {
    return ApiService.put('/v1/user/parent', { refer_string: newRef })
  },

  sign() {
    return ApiService.put('/v1/user/sign')
  },

  emailVerification() {
    return ApiService.post('/v2/auth/email/verify')
  },

  realNameVerification(payload) {
    return ApiService.post('v1/user/verify-real-name', { ...payload })
  },
}

export const RollService = {
  getAllRollRooms() {
    return ApiService.get('/v1/roll-rooms')
  },
  getJoinedRollRooms() {
    return ApiService.get('/v1/roll-rooms?joined=true')
  },
  getRollRoomById(id) {
    return ApiService.get(`/v1/roll-rooms/${id}`)
  },
  joinRoom(id, password) {
    return ApiService.post(`/v1/roll-rooms/${id}/join`, { password })
  },
}

export const NotificationService = {
  getNotifications() {
    return ApiService.get('/v1/user/notifications')
  },
  readNotification(id) {
    return ApiService.put(`/v1/user/notifications/read/${id}`)
  },
}

export const AppService = {
  getPage(name) {
    return ApiService.get(`/v1/page/${name}`)
  },
}

export const ConfigService = {
  getConfigInfo() {
    return ApiService.get('/v1/info')
  },
}

export const AnnoucementService = {
  init() {},

  getAnnoucement() {
    return axios.get('https://app.fail/lucksec.json')
  },
}
