<template>
  <TransitionRoot as="template" :show="visible">
    <Dialog as="div" auto-reopen="true" class="fixed z-50 inset-0 overflow-y-auto" @close="onClose">
      <div class="flex items-center justify-center min-h-screen sm:pt-4 sm:px-4 sm:pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-middle bg-white sm:rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:w-full sm:p-6"
            :class="{
              'sm:max-w-xs': size === 'sm',
              'sm:max-w-2xl': size === '2xl',
              'sm:max-w-3xl': size === '3xl',
              'sm:max-w-lg': !size || size === 'md',
              'bg-transparent': transparentBg,
            }"
          >
            <div class="block absolute top-0 right-0 pt-6 pr-4">
              <button
                v-if="!noCloseButton"
                type="button"
                class="bg-white rounded-md text-black-70 hover:text-black focus:outline-none"
                @click="emit('close')"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div>
              <div class="mt-3 text-center sm:mt-0 sm:mx-2">
                <DialogTitle as="h3" class="text-2xl leading-10 font-bold text-gray-900">
                  <slot name="title">{{ title }}</slot>
                </DialogTitle>
                <div class="mt-5 max-h-[calc(100vh-110px)] sm:max-h-full overflow-auto sm:overflow-visible">
                  <slot></slot>
                </div>
              </div>
            </div>
            <div v-if="slots['modal-ok'] || slots['modal-cancel']" class="mt-5 flex flex-row-reverse">
              <Button v-if="slots['modal-ok']" @click="emit('ok')" class="ml-4 w-32">
                <slot name="modal-ok"></slot>
              </Button>
              <Button v-if="slots['modal-cancel']" outline @click="emit('close')" class="w-32">
                <slot name="modal-cancel"></slot>
              </Button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { useSlots, watch } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogOverlay, DialogTitle } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

const emit = defineEmits(['close', 'ok', 'show'])
const slots = useSlots()

const props = defineProps({
  visible: Boolean,
  title: String,
  size: String,
  noCloseOnBackdrop: Boolean,
  noCloseButton: Boolean,
  transparentBg: Boolean,
})

function onClose() {
  if (!props.noCloseOnBackdrop) {
    emit('close')
  }
}

watch(
  () => props.visible,
  () => {
    if (props.visible) {
      emit('show')
    }
  }
)
</script>
