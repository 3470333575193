<template>
  <Modal :visible="showModal" :title="title" @show="onShow" no-close-button>
    <div class="content text-sm text-left" v-html="content"></div>
    <div class="mt-8">
      <!-- <Button outline @onclick="handleClickDisagree">不同意</Button> -->
      <Button @onclick="handleClickAgree" :disabled="countDown > 0">{{
        (countDown > 0 ? `${countDown}秒 ` : '') + '已阅读并同意'
      }}</Button>
    </div>
  </Modal>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import Modal from '@/components/Modal'

import { UserService, AppService } from '@/services/api'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const route = useRoute()
const store = useStore()
const showModal = ref(false)
const countDown = ref(5)
const title = ref('')
const content = ref('')
const countInterval = ref(null)

function countDownTimer() {
  countDown.value = 5
  countInterval.value = setInterval(() => {
    countDown.value -= 1
    if (countDown.value <= 0) {
      clearInterval(countInterval.value)
    }
  }, 1000)
}

// function onClose() {
//   showModal.value = false;
//   clearInterval(countInterval.value);
// }

function onShow() {
  countDownTimer()
}

function handleClickAgree() {
  sessionStorage.setItem('signed', 1)
  showModal.value = false
  UserService.sign()
    .then()
    .catch(e => {
      store.commit('SET_TOAST', {
        data: e.response,
        type: 'error',
      })
    })
}

async function checkSign() {
  await AppService.getPage('user-agreement').then(({ data }) => {
    content.value = data.content
    title.value = data.title
  })

  const signed = sessionStorage.getItem('signed')
  if (route.name === 'Page') return
  if (store.state.user.info.agreements) return
  if (
    store.state.user.isLogin &&
    store.state.user.info.agreements === false &&
    (!signed || signed === 'false' || signed === false)
  ) {
    showModal.value = true
  }
}

onMounted(() => {
  checkSign()
})

watch(
  () => route.path,
  () => {
    checkSign()
  }
)
</script>

<style scoped>
.content >>> a {
  color: royalblue;
}
</style>
