<template>
  <input
    type="text"
    v-bind="$attrs"
    class="appearance-none block w-full h-full px-3 py-3.5 border rounded-md focus:outline-none focus:ring-yellow focus:border-yellow sm:text-sm disabled:bg-gray-darkest disabled:text-black-50"
    :value="modelValue"
    @input="handleChange"
    :class="[!hasError ? 'border-gray-light' : 'border-red-400']"
  />
  <p class="text-red-500 text-sm mt-1 absolute">{{ error }}</p>
</template>

<script setup>
defineProps({
  modelValue: [String, Number],
  hasError: Boolean,
  error: String,
})

const emit = defineEmits(['update:modelValue'])

const handleChange = e => {
  emit('update:modelValue', e.target.value)
}
</script>
