<template>
  <footer class="bg-black-darkest w-full pb-8 sm:pb-0" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="container py-12 px-4 sm:px-6 sm:py-4 lg:py-16 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div class="xl:grid xl:grid-cols-1 xl:gap-9">
        <!-- 产品信息 -->
        <!-- <div class="space-y-8 xl:col-span-1">
          <h2 class="text-white text-2xl font-bold">{{ $t('footer.introduce[0]') }}</h2>
          <p class="text-gray text-sm leading-8 mt-4">
            {{ t('footer.introduce[1]') }}
            <span class="text-yellow">{{ t('footer.introduce[2]') }}</span>
            {{ t('footer.introduce[3]') }}
          </p>
        </div> -->
        <!-- 链接 -->
        <div class="mt-12 xl:mt-0 md:grid md:grid-cols-2 md:gap-9 text-left">
          <!-- <div>
            <h3 class="text-base leading-8 text-white tracking-wider uppercase">{{ t('footer.knowledge') }}</h3>
            <ul class="mt-4 space-y-1">
              <li v-for="item in navigation.knowledge" :key="item.name">
                <a :href="item.href" class="text-sm text-gray hover:text-white">{{ item.name }}</a>
              </li>
            </ul>
          </div> -->

          <div class="mt-12 md:mt-0">
            <h3 class="text-base leading-8 text-white tracking-wider uppercase">{{ t('footer.legaltitle') }}</h3>
            <ul class="mt-4 space-y-1 grid grid-cols-2">
              <li v-for="item in navigation.legals" :key="item.name">
                <a :href="item.href" class="text-sm text-gray hover:text-white">{{ item.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-if="showQrCode">
        <h6 class="text-white">扫一扫免费下载LuckSec App</h6>
        <div class="flex items-start gap-8">
          <div>
            <div class="flex flex-col items-center">
              <AppStoreCode class="mt-3" width="120" />
            </div>
            <a
              href="https://apps.apple.com/au/app/apple-store/id1604486135"
              target="_blank"
              class="flex justify-center mt-4"
            >
              <AppStore width="130" />
            </a>
          </div>
          <div>
            <div class="flex flex-col items-center">
              <!-- <div id="android-qrcode" class="mt-3"></div> -->
              <GoolePlayCode class="mt-3" width="120" />
            </div>
            <a
              :href="androidLinks"
              target="_blank"
              rel="noopener noreferrer"
              class="flex justify-center mt-4"
              aria-label="google-play"
            >
              <GoolePlay width="130" />
            </a>
          </div>
        </div>
      </div>

      <div class="mt-8 text-sm text-gray md:mt-0">
        <p>
          <a>{{ t('footer.rights') }}</a>
          <span>{{ t('footer.reserve') }}</span>
        </p>
        <p>{{ t('footer.email') }}</p>
      </div>

      <div class="flex space-x-2">
        <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray">
          <span class="sr-only">{{ item.name }}</span>
          <img :src="item.icon" class="h-10 w-10" :alt="item.name" />
        </a>
      </div>
    </div>

    <CopyRight v-if="showCopyRight" />
  </footer>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import FacebookSVG from '@/assets/social/facebook.svg'
import InstagramSVG from '@/assets/social/instagram.svg'
import TwitterSVG from '@/assets/social/twitter.svg'
import EmailSVG from '@/assets/social/email.svg'
import CopyRight from './CopyRight'
import AppStoreCode from '@/components/svgs/AppStoreCode'
import AppStore from '@/components/svgs/AppStore'
import GoolePlayCode from '@/components/svgs/GooglePlayCode'
import GoolePlay from '@/components/svgs/GoolePlay'

const { t } = useI18n()
const route = useRoute()

const showCopyRight = computed(() => !window.location.host?.includes('lucksec.com'))
const showQrCode = computed(() => route.params.page !== 'privacy')

const navigation = {
  // knowledge: [
  //   { name: t('footer.base[0]'), href: '#' },
  //   { name: t('footer.base[1]'), href: '#' },
  //   { name: t('footer.base[2]'), href: '#' },
  //   { name: t('footer.base[3]'), href: '#' },
  //   { name: t('footer.base[4]'), href: '#' },
  // ],
  legals: [
    {
      name: t('footer.legal[0]'),
      href: '/page/user-agreement',
    },
    {
      name: t('footer.legal[1]'),
      href: '/page/transport-policy',
    },
    {
      name: t('footer.legal[2]'),
      href: '/page/privacy-policy',
    },
    {
      name: t('footer.legal[3]'),
      href: '/page/about-us',
    },
    {
      name: t('footer.legal[4]'),
      href: '/page/shopping-service',
    },
    {
      name: t('footer.legal[5]'),
      href: '/page/serve-rule',
    },
    {
      name: t('footer.legal[6]'),
      href: '/page/return-policy',
    },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: FacebookSVG,
    },
    {
      name: 'Instagram',
      href: '#',
      icon: InstagramSVG,
    },
    {
      name: 'Twitter',
      href: '#',
      icon: TwitterSVG,
    },
    {
      name: 'Email',
      href: '#',
      icon: EmailSVG,
    },
  ],
}

const androidLinks = ref(
  'https://a.app.qq.com/o/simple.jsp?pkgname=com.lucksec&fromcase=70051&g_f=1182517&scenevia=XQYFX'
)

onMounted(() => {
  // const dt = Date.now()
  /**
   *  List of options:
        content - QR Code content, the only required parameter
        padding - white space padding, 4 modules by default, 0 for no border
        width - QR Code width in pixels
        height - QR Code height in pixels
        color - color of modules (squares), color name or hex string, e.g. #000000
        background - color of background, color name or hex string, e.g. white
        ecl - error correction level: L, M, H, Q
        join - join modules (squares) into one shape, into the SVG path element, recommended for web and responsive use, default: false
        predefined - to create a squares as pattern, then populate the canvas, default: false, see the output examples below
        pretty - apply indents and new lines, default: true
        swap - swap X and Y modules, only if you have issues with some QR readers, default: false
        xmlDeclaration - prepend XML declaration to the SVG document, i.e. <?xml version="1.0" standalone="yes"?>, default: true
        container - wrapping element, default: svg, see below

      Container options:
        svg - populate squares in a SVG document with width and height attriute, recommended for converting to raster images or PDF where QR Code is being static (exact size)
        svg-viewbox - populate squares in a SVG document with viewBox attriute, recommended for responsive web pages
        g - put squares in g element, useful when you need to put multiple QR Codes in a single SVG document
        none - no wrapper
   */
  // const svg = new QRCode({
  //   content: androidLinks.value,
  //   padding: 3,
  //   width: 120,
  //   height: 120,
  //   color: '#000000',
  //   background: '#ffffff',
  //   ecl: 'M',
  // }).svg()
  // document.getElementById('android-qrcode').innerHTML = svg
})
</script>
