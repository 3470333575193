<template>
  <nav class="flex container px-4 text-black text-xs sm:text-sm my-4 sm:my-10" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-0.5 font-medium">
      <li>
        <router-link :to="{ name: 'Home' }" class="hover:opacity-75">
          {{t("route.home")}}
          <span class="sr-only">Home</span>
        </router-link>
      </li>
      <li v-for="(bread, k) in breadcrumbList" :key="k">
        <div class="flex items-center">
          <ChevronRightIcon class="flex-shrink-0 h-3 w-3" aria-hidden="true" />
          <div v-if="bread.to === '-1'" class="ml-0.5 hover:opacity-75 cursor-pointer" @click="$router.go(-1)">
            {{ t(`route.${bread.text}`) }}
          </div>
          <router-link
            v-else
            :to="getPath(k, bread.to)"
            class="ml-0.5 hover:opacity-75"
            :aria-current="route.name === bread.text ? 'page' : undefined"
            >{{ t(`route.${bread.text}`) }}</router-link
          >
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ChevronRightIcon } from '@heroicons/vue/outline'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const breadcrumbList = ref([])

const route = useRoute()

const getPath = (idx, to) => {
  if (breadcrumbList.length - 1 !== idx) {
    return { name: to }
  }
  return ''
}

onMounted(() => {
  breadcrumbList.value = route.meta.breadCrumbs
})

watch(
  () => route.path,
  () => {
    breadcrumbList.value = route.meta.breadCrumbs
  }
)
</script>
