// import { AppService } from '@/services/api'

export default {
  namespaced: true,
  state: () => ({
    boxListCache: {},
    loading: false,
  }),
  mutations: {
    setLoading: (state, payload) => {
      state.loading = payload
    },
    setBoxListCache: (state, payload) => {
      state.boxListCache = payload
    },
  },
  actions: {
    addBoxListCache({ commit, state }, payload) {
      const oldValue = state.boxListCache
      commit('setBoxListCache', { ...oldValue, ...payload })
    },
  },
  getters: {
    loading(state) {
      return state.loading
    },
    boxListCache(state) {
      return state.boxListCache
    },
  },
}
