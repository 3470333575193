export default {
  app: state => state.app,
  stat: state => state.app.stat,
  box: state => state.box,
  boxLoading: state => state.boxLoading,
  user: state => state.user,
  toast: state => state.toast,
  modal: state => state.modal,
  inventory: state => state.inventory,
  lang: state => state.lang,
  muted: state => state.muted,
};
