<template>
  <router-link class="drop-i m-1 sm:m-2.5 pb-1" :to="`/drop-history/` + drop.link">
    <div
      class="drop w-[100px] h-[100px] sm:w-[180px] sm:h-[180px] bg-white box-border relative text-center rounded-lg transition-all overflow-hidden shadow-sm group"
    >
      <!-- item img -->
      <div
        class="w-full h-[58%] bg-no-repeat bg-contain bg-center group-hover:opacity-0 absolute top-[5%]"
        :style="{
          backgroundImage: 'url(' + image + ')',
        }"
      ></div>
      <!-- box img -->
      <div
        v-if="box"
        class="w-full h-full absolute bg-no-repeat bg-cover bg-center opacity-0 group-hover:opacity-100"
        :style="
          hoverImage ? 'background-image:url(' + hoverImage + ');background-color:#fff' : 'background:transparent'
        "
      >
        <div
          class="w-full h-[40px] leading-[40px] bottom-[50%] text-sm bg-black-50 text-white absolute translate-y-1/2"
        >
          {{ drop.user_name }}
        </div>
      </div>
      <div
        v-if="drop.type === 'buy-sku'"
        class="w-full h-full absolute bg-no-repeat bg-center opacity-0 group-hover:opacity-100 bg-white"
        :style="{
          backgroundImage: 'url(' + StoreSVG + ')',
        }"
      >
        <div
          class="w-full h-[40px] leading-[40px] bottom-[50%] text-[8px] sm:text-sm bg-black-50 text-white absolute translate-y-1/2"
        >
          {{ drop.user_name }}
        </div>
      </div>
      <div class="h-[40px] group-hover:opacity-0 whitespace-nowrap text-center flex absolute bottom-[6%]">
        <span
          class="leading-tight text-[8px] sm:text-xs absolute inset-x-0 bottom-0 pl-3 font-bold"
          v-html="caption"
        ></span>
      </div>
      <svg
        class="absolute -bottom-2 left-0"
        :class="{
          ['text-rarities-' + realRarity]: drop,
        }"
        width="100%"
        height="22"
        viewBox="0 0 180 8"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path opacity="0.2" d="M180 0L90 5.33333V8H180V0Z" />
        <path opacity="0.6" d="M0 0L180 6V8H0V0Z" />
      </svg>
    </div>
  </router-link>
</template>

<script setup>
import { ASSET_URL } from '@/config'
import { computed } from 'vue'
import StoreSVG from '@/assets/drop/store.svg'
import getRarity from '@/utils/rarity'
import { getSkusPriceRange } from '@/utils/finance'

const props = defineProps({
  drop: Object,
})

const box = computed(() => props.drop.box)

const image = computed(() => ASSET_URL + props.drop.sku.image)
const hoverImage = computed(() => {
  if (box.value) return ASSET_URL + box.value.image
  if (props.drop.type === 'open-user-box') return ''
  return ''
})
const caption = computed(() => {
  if (!props.drop || !props.drop.product) return ''
  return props.drop.product.caption
})
// const avatar = computed(() => props.drop.sku);

const priceRange = computed(() => getSkusPriceRange([props.drop.sku]))
const realRarity = computed(() => getRarity(priceRange.value.max))
</script>

<style scoped>
.drop-i {
  animation: drop-in 500ms normal forwards ease-in-out;
}

.drop-i .drop {
  -webkit-transform-origin-x: 0;
  animation: drop-in-item 500ms normal forwards ease-in-out;
}

@keyframes drop-in {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 180px;
  }
}

@keyframes drop-in-item {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
</style>
