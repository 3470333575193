const errorParser = error => {
  const rv = {
    message: '',
    tag: false,
  }
  if (!error) {
    return null
  }
  if (typeof error === 'string') {
    rv.message = error
  } else if (error.status === 422) {
    if (error.data.errors) {
      const em = Object.values(error.data.errors)[0][0]
      rv.message = em || '发生错误'
    }
    if (error.data.error) {
      rv.message = error.data.error.data || '发生错误'
    }
  } else if (error.status === 404 || error.status === 500) {
    rv.message = '服务器发生错误'
    rv.tag = true
  } else if (error.status >= 400 && error.status < 500) {
    if (error.data.message) {
      rv.message = error.data.message
    } else if (error.data.error) {
      rv.message = error.data.error.data || '发生错误'
    } else {
      rv.message = error.data
    }
  }
  return rv
}

export default errorParser
