export default function getRarity(price) {
  switch (true) {
    case price > 10000:
      return 0;
    case price > 5000:
      return 1;
    case price > 1000:
      return 2;
    case price > 500:
      return 3;
    case price > 100:
      return 4;
    case price > 50:
      return 5;
    case price > 10:
      return 6;
    default:
      return 7;
  }
}
